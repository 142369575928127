import { Address, Carrier, Cart, MasterShopInfo, OrderEntry, PriorityInfo } from '@/src/core/apim';
import { Notification, getNotificationMapping } from '@/src/core/services/notification-mapping';
import { TextKey } from '@/src/core/services/text-key';
import { NotificationTypes, OwningSystem } from '@/src/core/types/api';
import { Seller } from '@/src/core/types/interfaces';
import { RuleStatus } from '@/src/core/types/rule-status';
import { DateFormatUTC } from '@/src/core/utils/dates';
import {
  FailIndicators,
  MappedCartEntries,
} from '@/src/market/services/legacy-cart-modification-mapping';
import { addCommerceCDNtoUrl } from '@/src/market/services/legacy-mapping';
import { MappedCartEntry, mapCartEntry } from '@/src/market/services/legacy-mapping-cart-entry';
import { handleMasterShopMapping } from '@/src/market/services/legacy-mapping-mastershop';
import { mapPrice } from '@/src/market/services/legacy-mapping-price';
import { cloneDeep } from 'lodash';

const shouldShowTotal = (entries?: OrderEntry[]) => {
  if (!entries) {
    return false;
  }

  return (
    entries?.every(
      (entry) =>
        entry.priceAvailable ||
        (entry.product?.owningSystem === OwningSystem.BLUE &&
          entry.product?.productRule?.sellable === RuleStatus.YES),
    ) ?? false
  );
};

const hasEntryWithMinOrderValue = (entries?: OrderEntry[]) => {
  if (!entries) {
    return false;
  }

  return entries.some((entry) => entry.infoStatus == 'MINIMUM_ORDER_LINE_VALUE');
};

const hasEntryWithUnknownDeliveryDate = (entries?: OrderEntry[]) => {
  if (!entries) {
    return false;
  }

  return entries.some((entry) => entry.infoStatus == 'UNKNOWN_DELIVERYDATE');
};

export const hasEntryWithAddressRestricted = (entries?: OrderEntry[]) => {
  if (!entries) {
    return false;
  }

  return entries.some((entry) => entry.orderableStatus?.toLowerCase() == 'restricted');
};

const formatCarrierInstructions = (carrierInstructions: string) => {
  if (carrierInstructions.includes('Instruction:')) {
    const instructionIndex = carrierInstructions.indexOf(' Instruction:') + 14;
    return carrierInstructions.substring(instructionIndex);
  }
  return '';
};

const getDeliveryAddress = (address?: Address) => {
  if (!address) {
    return;
  }
  const { country, ...restAddress } = address ?? {};
  const { isocode, ...restCountry } = country ?? {};

  return {
    country: {
      isoCode: country?.isocode,
      ...restCountry,
    },
    vatId: '',
    ...restAddress,
  };
};

const mapCarrier = (carrier?: Carrier) => {
  return {
    preferred: carrier?.preferred || false,
    serviceLevelRequired: carrier?.serviceLevelRequired || false,
    code: '',
    name: '',
  };
};

export const mapCartSellerGroup = (cart: Cart) => {
  return cart.masterShopInfos?.map((masterShopInfo) => mapCartSeller(masterShopInfo));
};

const mapCartSeller = (masterShopInfo: MasterShopInfo) => {
  const key = masterShopInfo.masterShopId;
  const name = masterShopInfo.masterShopId;

  const namedDeliveryDate =
    masterShopInfo.namedDeliveryDate && DateFormatUTC(masterShopInfo.namedDeliveryDate);

  const carrier = mapCarrier(masterShopInfo.forwarder?.carrier);
  const carrierInfo = masterShopInfo.forwarder?.forwarderAccountNumber;
  let carrierInstructions;
  let logo;
  let serviceLevelAgreements;

  const deliveryAddress = getDeliveryAddress(masterShopInfo.shippingAddress);

  const priorityInfo = mapPriorityInfo(masterShopInfo.priorityInfo);
  let priorityFee;

  const price = mapPrice(masterShopInfo.price);

  let entriesCount;
  const entries: MappedCartEntry[] = [];

  const hasMinOrderLineValue = false;
  const hasMinOrderValue = false;
  const hasAddressRestrictedEntries = false;
  const minOrderLineValue = 0;
  const minOrderValue = 0;
  let termsAndPolicies;
  const customerCode = masterShopInfo.customerCode;
  const orderType = masterShopInfo.orderType;
  const purchaseOrderNumber = masterShopInfo.purchaseOrderNumber;

  // Assuming Key is being used in the context of a URL
  const url = `/market/cart/checkout/seller/${key}`;
  let masterShopLogo;

  if (masterShopInfo.forwarder?.forwarderName !== null) {
    carrier.name = masterShopInfo.forwarder?.forwarderName || '';
    carrier.code = 'CUSTOMCARRIER';
    carrierInstructions = masterShopInfo.forwarder?.additionalInfo || '';
  } else {
    carrierInstructions = masterShopInfo.forwarder?.additionalInfo;
    serviceLevelAgreements = masterShopInfo.forwarder?.serviceLevelAgreements;
    carrierInstructions = formatCarrierInstructions(
      masterShopInfo?.forwarder?.additionalInfo || '',
    );
  }

  return {
    key,
    name,
    namedDeliveryDate,
    carrier,
    carrierInfo,
    carrierInstructions,
    logo,
    serviceLevelAgreements,
    deliveryAddress,
    priorityInfo,
    priorityFee,
    price,
    entriesCount,
    entries,
    hasMinOrderLineValue,
    hasMinOrderValue,
    hasAddressRestrictedEntries,
    minOrderLineValue,
    minOrderValue,
    termsAndPolicies,
    customerCode,
    orderType,
    purchaseOrderNumber,
    url,
    masterShopLogo,
  };
};

const mapPriorityInfo = (priorityInfo?: PriorityInfo) => {
  if (!priorityInfo) {
    return {};
  }

  return {
    priority: {
      code: priorityInfo?.priority?.code ?? '',
      name: priorityInfo?.priority?.name ?? '',
    },
    airframe: priorityInfo.airframe || '.',
  };
};

export const mapCartEntries = (entries: OrderEntry[] | undefined, cart: Cart) => {
  if (!entries) {
    return [];
  }
  return entries.map((entry) => mapCartEntry(entry, cart));
};

export const mapCart = (cart: Cart) => {
  const priority = cart.priorityInfo?.priority.code;
  const priorityInfo = mapPriorityInfo(cart.priorityInfo);
  const sellerGroup = mapCartSellerGroup(cart);
  const hasMarketplaceSellers = Boolean(
    sellerGroup?.find(
      (seller) =>
        seller.key !== Seller.Airbus.toLowerCase() && seller.key !== Seller.Satair.toLowerCase(),
    ),
  );
  const showTotal = shouldShowTotal(cart.entries);
  const saveTime = cart.saveTime || '0001-01-01T00:00:00';
  const modifiedTime = cart.modifiedTime || '0001-01-01T00:00:00';
  const creationTime = cart.creationTime || '0001-01-01T00:00:00';
  const isSaved = Boolean(cart.saveTime && cart.saveTime !== '0001-01-01T00:00:00');
  const hasMinOrderLineValue = hasEntryWithMinOrderValue(cart.entries);
  const unknownDeliveryDate = hasEntryWithUnknownDeliveryDate(cart.entries);
  const hasAddressRestrictedEntries = hasEntryWithAddressRestricted(cart.entries);
  const name = cart !== null && !isSaved ? TextKey('UI_CART_TITLE') : cart.name;
  const entries = mapCartEntries(cart.entries, cart);
  const rawEntries = cloneDeep(entries);
  const hasEntriesWithMultipleWarehouse = Boolean(
    cart.warehouseOrderEntryGroups?.some(
      (group) => group.orderEntries && group.orderEntries.length > 1,
    ),
  );
  const mastershop = handleMasterShopMapping(cart.masterShop);
  const subTotal = mapPrice(cart.subTotal);
  const totalPrice = mapPrice(cart.totalPrice);
  const savedBy = cart.savedBy?.name;
  const cartChangedExternally = cart.cartChangedExternally || false;
  const deliveryAddress = getDeliveryAddress(cart.deliveryAddress);

  // hardcoded values
  const isFull = true;

  const mappedCart = {
    ...cart,
    priority,
    priorityInfo,
    sellerGroup,
    hasMarketplaceSellers,
    showTotal,
    saveTime,
    isSaved,
    isFull,
    hasEntriesWithMultipleWarehouse,
    hasMinOrderLineValue,
    unknownDeliveryDate,
    hasAddressRestrictedEntries,
    name,
    rawEntries,
    entries,
    mastershop,
    subTotal,
    totalPrice,
    savedBy,
    cartChangedExternally,
    modifiedTime,
    creationTime,
    deliveryAddress,
  };

  // grouping of entries is necessary because SAP CC returns multiple entries for the same product
  // with different warehouses
  const cartWithGrouppedEntries = legacyProductGroupingMapping(mappedCart);
  const cartWithGrouppedSellerEntries = legacySellerGroupingMapping(cartWithGrouppedEntries);

  return cartWithGrouppedSellerEntries;
};

export const checkIfRoundingChanged = (cart: Cart) => {
  const changedEntries = cart.entries?.filter((entry) =>
    entry.changeReasons?.some((reason) => reason.reason === 'quantityRoundedBySap'),
  );
  const notifications: Notification[] = [];

  changedEntries?.forEach((entry) => {
    if (entry.product?.owningSystem == OwningSystem.BLUE) {
      notifications.push(
        getNotificationMapping({
          title: `Quantity changed for ${entry.product.materialNumberExternal}`,
          description:
            'Full quantity cannot be delivered from default location. Your requested quantity was adjusted due to Airbus MOQ/SPQ policies.',
          type: NotificationTypes.Warning,
        }),
      );
    } else {
      notifications.push(
        getNotificationMapping({
          title: 'Quantity adjusted',
          description: `The quantity of product ${entry.product?.manufacturerAid}:${entry.product?.manufacturerData?.cageCode} has been adjusted due to package values`,
          type: NotificationTypes.Information,
        }),
      );
    }
  });

  return notifications;
};

export const getCartChangeNotifications = (cart: Cart) => {
  const notifications: Notification[] = [];

  cart.entries?.forEach((entry) => {
    if (entry.changeReasons) {
      entry.changeReasons.forEach((reason) => {
        notifications.push(
          getNotificationMapping({
            title: `Quantity for ${entry.product?.materialNumberExternal} has changed.`,
            description: `The quantity has changed from ${reason.oldValue} to ${reason.newValue}.`,
            type: NotificationTypes.Warning,
          }),
        );
      });
    }
  });

  cart.removedCartEntries?.forEach((entry) => {
    notifications.push(
      getNotificationMapping({
        title: `Unavailable product removed ${entry.partNumber}:${entry.cageCode}`,
        description:
          'The product in your cart is currently unavailable for purchase. We have removed the item.',
        type: NotificationTypes.Warning,
      }),
    );
  });

  return notifications;
};

export const getCartFailIndicators = (addedCartEntries: MappedCartEntries, sku: string) => {
  let notification;
  let customAction;

  switch (addedCartEntries.failIndicator) {
    case FailIndicators.Partial:
      notification = getNotificationMapping({
        title: `Product (${sku}) have successfully been added to cart`,
        notificationAction: {
          Url: `/market/cart`,
          Name: 'Go to cart',
        },
        description: `Please note that ${addedCartEntries.failCount} of your products could not be added.`,
        type: NotificationTypes.Success,
      });
      break;
    case FailIndicators.Full:
      if (
        addedCartEntries?.cartModifications?.[0].statusCode == 'NO_PRICE_AVAILABLE_BUT_QUOTABLE'
      ) {
        notification = getNotificationMapping({
          title: 'Add to cart failed',
          description: 'We could not add the product to your cart since its not sellable',
          type: NotificationTypes.Error,
        });
      } else {
        notification = getNotificationMapping({
          title:
            'This list could not be added to cart, as it consists of products that is not available in your region.',
          type: NotificationTypes.Error,
        });
      }
      break;
    case FailIndicators.MaxAllowedQuantityReached:
      notification = getNotificationMapping({
        title: 'Product was not added to cart. Max allowed quantity reached.',
        type: NotificationTypes.Error,
      });
      break;
    case FailIndicators.IncorrectCurrency:
      customAction = {
        actionType: 'TriggerModal',
        modalData: { name: 'ModalCartCurrency', data: 'list' },
      };
      break;
  }

  return { notification, customAction };
};

const legacyProductGroupingMapping = (cart: Cart) => {
  // Adapt List<CartEntry> equivalent in TypeScript, assuming a direct assignment or mapping function
  const dest = { ...cart };

  if (!dest.entries || dest.entries.length === 0) {
    return dest;
  }

  // Any equivalent in TypeScript
  dest.hasEntriesWithMultipleWarehouse = cart.warehouseOrderEntryGroups?.some(
    (x) => x.orderEntries && x.orderEntries.length > 1,
  );

  const satairMastershops = cart.masterShopInfos
    ?.filter((u) => u.masterShopId === Seller.Satair.toLowerCase())
    .flatMap((u) => u.warehouseGroups);

  const satairWarehouseGroups = cart.warehouseOrderEntryGroups?.filter((x) =>
    satairMastershops?.includes(x.warehouseEntryNumber),
  );

  // Set warehouse group on all entries
  dest.entries?.forEach((y) => {
    const warehouseGroup = cart.warehouseOrderEntryGroups?.find((x) =>
      x.orderEntries?.includes(y.entryNumber),
    );
    y.warehouseGroupEntryNumber = warehouseGroup?.warehouseEntryNumber;

    if (!y.isSatairProduct) {
      y.product.unit = y.salesUnit;
    }
  });

  const restEntries = dest.entries?.filter(
    (y) =>
      !satairWarehouseGroups?.some((x) => x.orderEntries && x.orderEntries.includes(y.entryNumber)),
  );

  const entries = satairWarehouseGroups?.map((x) => {
    const entryNumber = x.orderEntries?.[0]; // first reference
    const entry = dest.entries?.find((y) => y.entryNumber === entryNumber);

    if (x.orderEntries && x.orderEntries.length > 1 && entry) {
      entry.availabilityList = x.orderEntries
        .map((y) => dest.entries?.find((p) => p.entryNumber === y))
        .flatMap((t) => {
          if (
            !t?.availabilityInfos ||
            !t?.availabilityList ||
            t?.availabilityInfos?.length === 0 ||
            t?.availabilityList?.length === 0
          ) {
            return [
              {
                code: t.product.code,
                stockCount: t.quantity ?? 0,
                warehouse: t.warehouse,
                basePrice: t.basePrice,
                totalPrice: t.totalPrice,
                availableStockQuantity: t.warehouseQuantity,
                entryNumber: t.entryNumber,
              },
            ];
          }
          t.availabilityList[0].availableStockQuantity = t.warehouseQuantity;
          t.availabilityList[0].basePrice = t.basePrice;
          t.availabilityList[0].totalPrice = t.totalPrice;
          t.availabilityList[0].entryNumber = t.entryNumber;

          return t.availabilityList;
        })
        .sort(
          (a, b) =>
            (a.availableDate ?? new Date(8640000000000000)) -
            (b.availableDate ?? new Date(8640000000000000)),
        );

      entry.hasMultipleWarehouse = true;
      entry.singleCustomLocation = false;

      // Assuming `Adapt<ProductPrice>` simply copies fields in TypeScript
      entry.basePrice = { ...x.basePrice };
      entry.totalPrice = { ...x.totalPrice };
      entry.quantity = x.totalQuantity;
    }

    if (x.orderEntries?.length === 1 && entry) {
      entry.availabilityList = x.orderEntries
        .map((y) => dest.entries?.find((p) => p.entryNumber === y))
        .flatMap((t) => {
          if (
            !t?.availabilityInfos ||
            !t?.availabilityList ||
            t?.availabilityInfos?.length === 0 ||
            t?.availabilityList?.length === 0
          ) {
            return [
              {
                code: t?.product?.code,
                stockCount: t?.quantity ?? 0,
                warehouse: t?.warehouse,
                basePrice: t?.basePrice,
                totalPrice: t?.totalPrice,
                availableStockQuantity: t?.warehouseQuantity,
                unknownDeliveryDate: t?.unknownDeliveryDate,
                entryNumber: t?.entryNumber,
              },
            ];
          }
          t.availabilityList[0].availableStockQuantity = t.warehouseQuantity;
          t.availabilityList[0].basePrice = t.basePrice;
          t.availabilityList[0].totalPrice = t.totalPrice;
          t.availabilityList[0].entryNumber = t.entryNumber;

          return t.availabilityList;
        })
        .sort(
          (a, b) =>
            (a.availableDate ?? new Date(8640000000000000)) -
            (b.availableDate ?? new Date(8640000000000000)),
        );
    }

    if (!entry?.hasMultipleWarehouse && entry?.defaultLocation) {
      entry.singleCustomLocation =
        entry.defaultLocation.code !== entry.warehouse?.code && entry.enableCustomizeWarehouse;
    }

    return entry;
  });

  dest.entries = [...entries, ...restEntries];
  return dest;
};

const legacySellerGroupingMapping = (cart: Cart) => {
  const dest = { ...cart };

  if (dest.entries?.length === 0) {
    dest.sellerGroup = [];
    return dest;
  }

  if (cart.masterShopInfos?.flatMap((x) => x.warehouseGroups).length > 0) {
    dest.sellerGroup = cart.masterShopInfos.map((x) => {
      const sellerGroup =
        dest.sellerGroup?.find((sellerGroup) => sellerGroup.key === x.masterShopId) || {};

      sellerGroup.entries = dest.entries?.filter(
        (y) => x.warehouseGroups && x.warehouseGroups.includes(y.warehouseGroupEntryNumber),
      );
      sellerGroup.name =
        sellerGroup.entries[0]?.masterShop?.name ??
        sellerGroup.entries[0]?.offerInfo?.shopName ??
        sellerGroup.name;
      sellerGroup.entriesCount = sellerGroup.entries.length;
      sellerGroup.hasMinOrderLineValue = sellerGroup.entries.some((y) => y.hasMinOrderLineValue);
      sellerGroup.hasAddressRestrictedEntries = sellerGroup.entries.some(
        (y) => y.orderableStatus.toLowerCase() === 'restricted',
      );
      sellerGroup.masterShopLogo = x.masterShopLogo ? addCommerceCDNtoUrl(x.masterShopLogo) : null;

      if (sellerGroup.key !== Seller.Satair.toLowerCase()) {
        sellerGroup.hasMinOrderLineValue = sellerGroup.entries.some((y) => y.hasMinOrderLineValue);
        sellerGroup.hasMinOrderValue = sellerGroup.entries.some((y) => y.hasMinOrderValue);
        sellerGroup.minOrderLineValue =
          sellerGroup.entries[0]?.masterShop?.minimumOrderLineValueUsd ?? 0;
        sellerGroup.minOrderValue = sellerGroup.entries[0]?.masterShop?.minimumOrderValueUsd ?? 0;
      }

      return sellerGroup;
    });
  } else {
    // Not enough info, put all in one seller group
    const masterShopId = cart.masterShopInfos?.sort(
      (a, b) =>
        (a.masterShopId === Seller.Satair.toLowerCase() ? 0 : 1) -
        (b.masterShopId === Seller.Satair.toLowerCase() ? 0 : 1),
    )[0]?.masterShopId;

    const sellerGroup =
      dest.sellerGroup?.find((sellerGroup) => sellerGroup.key === masterShopId) || {};

    sellerGroup.entries = dest.entries;
    sellerGroup.name = sellerGroup?.name;
    sellerGroup.entriesCount = dest.entries?.length;

    dest.sellerGroup = [sellerGroup];
  }

  // Sort by Satair first, then by name
  dest.sellerGroup.sort((a, b) => {
    const satairOrder =
      (a.key === Seller.Satair.toLowerCase() ? 0 : 1) -
      (b.key === Seller.Satair.toLowerCase() ? 0 : 1);
    return satairOrder !== 0 ? satairOrder : a.name?.localeCompare(b.name) || 0;
  });

  return dest;
};
