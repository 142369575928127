import { Cart, OfferInfo, OrderEntry } from '@/src/core/apim';
import { Seller } from '@/src/core/types/interfaces';
import { CultureService, DateFormatUTC, parseDate } from '@/src/core/utils/dates';
import { attachAsmHidden } from '@/src/market/services/legacy-mapping-entry';
import { handleMasterShopMapping } from '@/src/market/services/legacy-mapping-mastershop';
import { mapPrice } from '@/src/market/services/legacy-mapping-price';
import { getProductWithBaseMapping } from '@/src/market/services/legacy-mapping-product';

const NON_ORDERABLE_STATUSES = [
  'limitedbycrossplantmaterialstatus',
  'nopriceavailable',
  'noquantityremaining',
];

export const mapCartEntry = (entry: OrderEntry, cart: Cart) => {
  const id = entry.offerInfo?.id || entry.product?.id;
  const baseProduct = entry.product && getProductWithBaseMapping(entry.product, undefined, id);
  const offerInfo = mapOfferInfo(entry.offerInfo);
  const priceAvailable = entry.priceAvailable || false;
  const eligibleForVolumePrices = entry.eligibleForVolumePrices || false;
  const masterShop = handleMasterShopMapping(entry.masterShop);
  const totalPrice = mapPrice(entry.totalPrice);
  const basePrice = mapPrice(entry.basePrice);
  const offeredTotalPrice = mapPrice(entry.offeredTotalPrice);
  const offeredBasePrice = mapPrice(entry.offeredBasePrice);
  const containsUnknownMaterial = entry.containsUnknownMaterial || false;
  const cartEntryRoundedFromSap =
    entry.changeReasons?.some((reason) => reason.reason === 'quantityRoundedBySap') || false;
  const isSatairProduct = entry.masterShop?.id?.toLowerCase() === Seller.Satair.toLowerCase();
  const changeReasons = entry.changeReasons || [];
  const defaultLocation = {
    code: entry.defaultLocation?.code || '',
    name: entry.defaultLocation?.name || '',
  };
  const orderedQuantity =
    entry.quantity && entry.remainingQuantity
      ? entry.quantity - entry.remainingQuantity
      : undefined;
  const slowMoverPriceAvailable = entry.zproPriceAvailable || false;
  const hasMinOrderLineValue = entry.infoStatus == 'MINIMUM_ORDER_LINE_VALUE';
  const hasMinOrderValue = masterShop.minimumOrderValueUsd
    ? masterShop.minimumOrderValueUsd > 0
    : false;
  const unknownDeliveryDate = entry.infoStatus == 'UNKNOWN_DELIVERYDATE';
  const orderable = !NON_ORDERABLE_STATUSES.includes(entry.orderableStatus.toLowerCase());
  const entryIsCancelled = Boolean(
    entry.orderableStatus?.toLowerCase() == 'cancelled' ||
      entry.cancelReason ||
      entry.cancelReasonCode,
  );
  const hazmatFee = entry.fees?.find((fee) => fee.type === 'ZDAN' && fee.price);
  const state = entry.offerInfo?.state;
  const warehouseQuantity =
    entry.plantsStockDetails?.find((itemm) => itemm.warehouse?.code === entry.warehouse?.code)
      ?.quantity || 0;
  const warehouseGroup = cart.warehouseOrderEntryGroups?.find((group) =>
    group.orderEntries?.includes(entry.entryNumber),
  );
  const warehouseGroupEntryNumber = warehouseGroup?.warehouseEntryNumber;
  const availabilityList = getAvailabilityList(entry);
  const availabilityIndicator = getAvailabilityIndicator(availabilityList);
  const salesUnit = entry.salesUnit?.code || '';
  const priceUnit = entry.priceUnit?.code || salesUnit || '';
  const warehouse = entry.warehouse || { code: '', name: entry.offerInfo?.locationDisplayName };
  const product = {
    ...baseProduct,
    manufacturerAid: entry.containsUnknownMaterial
      ? entry.unknownPartNumber
      : baseProduct?.manufacturerAid,
    warehouse: entry.containsUnknownMaterial ? null : warehouse,
    packageQuantity:
      !isSatairProduct && entry.offerInfo?.packageQuantity && entry.offerInfo?.packageQuantity > 1
        ? entry.offerInfo?.packageQuantity
        : baseProduct?.packageQuantity,
    addToBasketQty:
      !isSatairProduct &&
      entry.offerInfo?.minimumOrderQuantity &&
      entry.offerInfo?.minimumOrderQuantity > 1
        ? entry.offerInfo?.minimumOrderQuantity
        : baseProduct?.addToBasketQty,
    cartStep:
      !isSatairProduct && entry.offerInfo?.packageQuantity && entry.offerInfo?.packageQuantity > 1
        ? entry.offerInfo?.packageQuantity
        : baseProduct?.cartStep,
  };

  const hasMultipleWarehouse =
    warehouseGroup?.orderEntries && warehouseGroup?.orderEntries.length > 1;
  const enableCustomizeWarehouse = true; // legacy: always true
  let singleCustomLocation = false;
  if (!hasMultipleWarehouse && defaultLocation) {
    singleCustomLocation =
      defaultLocation.code !== warehouse.code && enableCustomizeWarehouse ? true : false;
  }

  // hardcoded
  const stockStatus = 0;
  const price = {
    currencyIso: '',
    formattedValue: '',
    priceType: '',
    value: '0',
    infoStatus: '',
    isAsmHidden: false,
  };
  const showPrice = false;
  const showOrderButton = false;
  const shippingPrice = {
    minQuantity: 0,
    maxQuantity: 0,
    volumeDiscountPercentage: 0,
    currencyIso: '',
    formattedValue: '',
    priceType: '',
    value: '0',
    infoStatus: '',
    isAsmHidden: false,
  };
  const totalPriceWithShipping = {
    minQuantity: 0,
    maxQuantity: 0,
    volumeDiscountPercentage: 0,
    currencyIso: '',
    formattedValue: '',
    priceType: '',
    value: '0',
    infoStatus: '',
    isAsmHidden: false,
  };
  const initialQuoteRequest = false;
  const offerQuoteResponseAccepted = false;
  const offerQuoteResponseReady = false;
  const entryIsExpired = false;

  return {
    ...entry,
    id,
    product,
    warehouseGroupEntryNumber,
    offerInfo,
    masterShop,
    priceAvailable,
    eligibleForVolumePrices,
    totalPrice,
    basePrice,
    offeredBasePrice,
    offeredTotalPrice,
    containsUnknownMaterial,
    stockStatus,
    availabilityList,
    availabilityIndicator,
    cartEntryRoundedFromSap,
    isSatairProduct,
    changeReasons,
    hasMultipleWarehouse,
    enableCustomizeWarehouse,
    defaultLocation,
    singleCustomLocation,
    slowMoverPriceAvailable,
    price,
    showPrice,
    showOrderButton,
    hazmatFee,
    hasMinOrderLineValue,
    hasMinOrderValue,
    unknownDeliveryDate,
    shippingPrice,
    totalPriceWithShipping,
    initialQuoteRequest,
    offerQuoteResponseAccepted,
    offerQuoteResponseReady,
    orderable,
    entryIsExpired,
    entryIsCancelled,
    state,
    warehouseQuantity,
    salesUnit,
    priceUnit,
    warehouse,
    orderedQuantity,
  };
};

export type MappedCartEntry = ReturnType<typeof mapCartEntry>;

export const mapOfferInfo = (offerInfo?: OfferInfo) => {
  const minimumOrderQuantity = offerInfo?.minimumOrderQuantity || 1;
  const packageQuantity = offerInfo?.packageQuantity || 1;
  const discountPercentage = offerInfo?.discountPercentage || 0;
  const discountEndDate = offerInfo?.discountEndDate || '0001-01-01T00:00:00';
  const discountStartDate = offerInfo?.discountStartDate || '0001-01-01T00:00:00';
  const discountEndDateAvailable = Boolean(
    offerInfo?.discountEndDate && offerInfo?.discountEndDate !== '0001-01-01T00:00:00',
  );
  const discountPriceAvailable = Boolean(
    offerInfo?.discountPrice &&
      new Date(discountStartDate) <= new Date() &&
      new Date(discountEndDate) > new Date(),
  );
  const orderedWithDiscountPrice = Boolean(offerInfo?.discountPrice);

  return {
    ...offerInfo,
    minimumOrderQuantity,
    packageQuantity,
    discountPercentage,
    discountEndDate,
    discountStartDate,
    discountEndDateAvailable,
    discountPriceAvailable,
    orderedWithDiscountPrice,
  };
};

const getAvailabilityList = (src: OrderEntry | null) => {
  if (!src) return null;

  if (!src.availabilityInfos || src.availabilityInfos?.length === 0) return null;

  if (attachAsmHidden(src.infoStatus)) return null;

  // If in stock and only one availability info
  if (src.inStock && src.availabilityInfos && src.availabilityInfos.length === 1) {
    const warehouse = src.availabilityInfos[0].warehouse || src.warehouse;

    return [
      {
        warehouse: { ...warehouse }, // Replace with equivalent of `Adapt<CodeValue>` if necessary
        inStock: true,
        stockCount: src.availabilityInfos[0].quantity,
        availableDate: parseDate(src.availabilityInfos[0].availabilityDate, CultureService.us),
        isAsmHidden: attachAsmHidden(src.infoStatus) || false,
        unknownDeliveryDate: src.infoStatus == 'UNKNOWN_DELIVERYDATE',
        infoStatus: '',
      },
    ];
  }

  // If only one info and quantity is 0, return an empty list
  if (
    src.availabilityInfos &&
    src.availabilityInfos.length === 1 &&
    src.availabilityInfos[0].quantity === 0
  ) {
    return [];
  }

  // Filter availabilityInfos with quantity > 0
  const filteredAvailabilityInfos = src.availabilityInfos.filter(
    (x) => x.quantity && x.quantity > 0,
  );

  const entityWarehouse = src.warehouse;
  const availInfo = filteredAvailabilityInfos.map((x) => ({
    warehouse: x.warehouse ? { ...x.warehouse } : { ...entityWarehouse }, // Assuming shallow clone to replace `Adapt<CodeValue>`
    availableDate:
      src.infoStatus !== 'UNKNOWN_DELIVERYDATE'
        ? parseDate(x.availabilityDate, CultureService.us)
        : new Date(0), // Empty date if unknown
    inStock: false,
    stockCount: x.quantity,
    isAsmHidden: attachAsmHidden(src.infoStatus) || false,
    unknownDeliveryDate: src.infoStatus == 'UNKNOWN_DELIVERYDATE',
    infoStatus: '',
  }));

  // If `inStock`, mark the first delivery as `inStock`
  if (src.inStock && availInfo && availInfo.length > 0) {
    availInfo[0].inStock = true;
  }

  return availInfo;
};

const getAvailabilityIndicator = (availabilityList: any) => {
  const firstAvailability = availabilityList?.[0];
  const availableDate = DateFormatUTC(firstAvailability?.availableDate);
  const stockCount = firstAvailability?.stockCount;

  if (availableDate && stockCount > 0) {
    return availabilityList && availabilityList.length > 1
      ? `From ${availableDate}`
      : availableDate;
  } else {
    return 'N/A';
  }
};
