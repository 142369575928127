import { CertificateType, OfferSearch } from '@/src/core/apim/spec';
import { BaseOwningSystem, Seller } from '@/src/core/types/interfaces';

/**
 * Get the friendly name for a certificate type.
 * @param {string | null} certificateType
 * @returns {string}
 */
export function getCertificateFriendlyName(certificateType: string | null): string {
  if (!certificateType) {
    return 'N/A';
  }

  if (certificateType.includes('"code"')) {
    try {
      const parsed = JSON.parse(certificateType);
      return getFriendlyName(parsed.name);
    } catch (error) {
      console.error('Error parsing certificateType:', error);
      return 'N/A';
    }
  }

  return getFriendlyName(certificateType);
}

/**
 * Get the friendly name for a property.
 * @param {string} propertyName
 * @returns {string}
 */
export function getFriendlyName(propertyName: string): string {
  return propertyName == null || propertyName === '0' ? 'N/A' : propertyName;
}

export function populateCertificates(
  certificates: CertificateType[],
  certificateType: string | null,
): string[] {
  const defaultCertificate = certificateType
    ? certificateType.includes('"code"')
      ? getFriendlyName(JSON.parse(certificateType).name)
      : getFriendlyName(certificateType)
    : 'N/A';

  const list: string[] = [];
  if (certificates && certificates.length > 0) {
    certificates.forEach((item) => {
      if (item.name) {
        list.push(item.name);
      }
    });
  } else {
    list.push(defaultCertificate);
  }
  return list;
}

/**
 * Get the supplier display name based on owning system and master shop name.
 * @param {string | undefined} owningSystem
 * @param {string | undefined} masterShopName
 * @returns {string}
 */
export function getSupplierDisplay(
  owningSystem: string | undefined,
  masterShopName: string | undefined,
): string {
  if (owningSystem?.toLowerCase() === BaseOwningSystem.Blue) {
    return Seller.Airbus;
  } else if (owningSystem?.toLowerCase() === BaseOwningSystem.Red) {
    return Seller.Satair;
  } else {
    return masterShopName || Seller.Satair;
  }
}

/**
 * Get the URL for an offer.
 * @param {OfferSearch} offer
 * @returns {string}
 */
export function getOfferUrl(offer: OfferSearch): string {
  const cageCode = offer.cageCode || '';
  const manufacturerAid = offer.manufacturerAid || '';
  const owningSystem = offer.owningSystem?.toLowerCase() || '';
  const id = offer.id || '';

  const sellerKey = owningSystem === 'mirakl' ? id : owningSystem === 'blue' ? 'airbus' : 'satair';

  return `/${cageCode}/${manufacturerAid}/${sellerKey}`.toLowerCase();
}
