import { AddressList } from '@/src/core/apim';
import { getB2bUnitMapping } from '@/src/core/services/legacy-user-mapping';

export const mapCartAddresses = (addresses: AddressList) => {
  const mappedB2Bunit = getB2bUnitMapping(addresses);

  mappedB2Bunit.addresses.sort((a, b) => {
    if (a.defaultAddress !== b.defaultAddress) {
      return a.defaultAddress === b.defaultAddress ? 0 : a.defaultAddress ? -1 : 1;
    }
    if (b.id && a.id) return b.id > a.id ? 1 : b.id < a.id ? -1 : 0;
    return true;
  });

  return {
    ...mappedB2Bunit,
  };
};
