import { CartApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { Address, B2BUnit } from '@/src/core/types/api';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useDeliveryAddressStore = defineStore('delivery-addresses', () => {
  const allDeliveryAddresses = ref<Address[]>([]);
  const isBusy = ref<boolean>(false);

  const currentCostCenterUpdated = (payload: { AllCostCenters: B2BUnit; shopId?: string }) => {
    if (payload.shopId === 'airbus') {
      allDeliveryAddresses.value = payload.AllCostCenters?.BlueAddresses;
    } else {
      allDeliveryAddresses.value = payload.AllCostCenters?.RedAddresses;
    }
  };

  const isBusyUpdated = (payload: { IsBusy: boolean }) => {
    isBusy.value = payload.IsBusy;
  };

  const reset = () => {
    allDeliveryAddresses.value = [] as Address[];
    isBusy.value = false;
  };

  const fetchDeliveryAddresses = async (shopId?: string) => {
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess, Data } = await Req(
      {
        url: CartApi.FetchDeliveryAddresses('|'),
        params: { shopId, cartCode: '|' },
      },
      undefined,
      CartApi.handleFetchDeliveryAddresses,
    );

    if (IsSuccess) {
      currentCostCenterUpdated({ AllCostCenters: Data, shopId });
    }

    isBusyUpdated({ IsBusy: false });
  };

  const getDeliveryAddressById = (addressId: string) => {
    return (
      allDeliveryAddresses.value.find((deliveryAddress) => deliveryAddress.Id === addressId) ||
      ({} as Address)
    );
  };

  const defaultDeliveryAddressId = computed(() => {
    return allDeliveryAddresses.value.find((addr) => addr.DefaultAddress)?.Id;
  });

  return {
    isBusy,
    allDeliveryAddresses,
    defaultDeliveryAddressId,
    getDeliveryAddressById,
    reset,
    fetchDeliveryAddresses,
  };
});
