import { Price } from '@/src/core/apim';
import Cookies from 'js-cookie';

export const mapPrice = (price?: Price) => ({
  currencyIso: price?.currencyIso || '',
  formattedValue: price?.formattedValue || '',
  priceType: price?.priceType || '',
  value: price?.value || 0,
  infoStatus: price?.infoStatus || '',
  isAsmHidden: Cookies.get('asm') === 'true',
});
