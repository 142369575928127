// tslint:disable
import { UserApi } from '@/src/core/api';
import { Cart, CartEntryToAdd, apim } from '@/src/core/apim';
import { getEnv } from '@/src/core/services/environment';
import { toLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { Notification } from '@/src/core/services/notification-mapping';
import { B2BUnit, CartEntryDetails } from '@/src/core/types/api';
import { Seller } from '@/src/core/types/interfaces';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import { legacyErrorHandler } from '@/src/market/services/error-notification-handling';
import { mapAddedCartEntries } from '@/src/market/services/legacy-cart-modification-mapping';
import { productIdIsOffer } from '@/src/market/services/legacy-mapping';
import { mapCartAddresses } from '@/src/market/services/legacy-mapping-cart-addresses';
import { handleMasterShopMapping } from '@/src/market/services/legacy-mapping-mastershop';
import {
  getProductWithBaseMapping,
  setProductQty,
} from '@/src/market/services/legacy-mapping-product';
import { mapSimpleCart } from '@/src/market/services/legacy-mapping-simple-cart';
import { extendDocuments, getLeadShopId } from '@/src/market/services/seller';
import { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import {
  MappedCartEntry,
  checkIfRoundingChanged,
  getCartChangeNotifications,
  getCartFailIndicators,
  mapCart,
} from '../services/legacy-mapping-cart';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class CartApi {
  public static AddProduct = `${baseUrl}/api/addproduct`;
  public static GetCart = `${baseUrl}/api/getcart`;
  public static ClearCart = `${baseUrl}/api/clearcart`;
  public static DeleteCartEntry = (cartCode: string, entryId: number) =>
    `${baseUrl}/api/cart/${cartCode}/delete/${entryId}`;
  public static CartProductQuantity = (cartCode: string, entryId: string) =>
    `${baseUrl}/api/cart/${cartCode}/${entryId}/quantity`;
  public static CartEntries = `${baseUrl}/api/cart/entries`;
  public static FetchCarriers = (cartCode: string, sellerId: string) =>
    `${baseUrl}/api/cart/carriers/${cartCode}/${sellerId}`;
  public static FetchWarehouses = (productCode: string) =>
    `${baseUrl}/api/cart/entry/details?cartCode=current&productCode=${productCode}`;
  public static UpdateWarehouses = (productCode: string, calculated?: boolean) =>
    `${baseUrl}/api/cart/entry/details/update?cartCode=current&productCode=${productCode}&calculated=${calculated}`;
  public static UpdateCarrier = (cartCode: string, carrierCode: string) =>
    `${baseUrl}/api/cart/carrier/${cartCode}?code=${carrierCode}`;
  public static FetchDeliveryAddresses = (cartCode: string) =>
    `${baseUrl}/api/cart/deliveryaddresses/${cartCode}`;
  public static UpdateDeliveryAddresses = (cartCode: string, addressId: string) =>
    `${baseUrl}/api/cart/deliveryaddress/${cartCode}/${addressId}`;
  public static UpdateCartInfo = (cartCode: string) => `${baseUrl}/api/cart/info/${cartCode}`;
  public static UpdateCartInfoWithProjectId = (
    cartCode: string,
    ponumber: string,
    projectId: string,
  ) => `${baseUrl}/api/cart/info/${cartCode}/${ponumber}/${projectId}`;
  public static UpdatePriority = (cartCode: string, sellerKey: string) =>
    `${baseUrl}/api/cart/priority/${cartCode}?shopId=${sellerKey}`;
  public static UpdateRemark = (cartCode: string, entryNumber: string) =>
    `${baseUrl}/api/cart/${cartCode}/entries/${entryNumber}`;
  public static UpdateOrderType = (orderType: string, cartId: string, masterShopId: string) =>
    `${baseUrl}/api/cart/ordertype?orderType=${orderType}&cartId=${cartId}&masterShopId=${masterShopId}`;
  public static SetCustomerCode = (customerCode: string, cartId: string, masterShopId: string) =>
    `${baseUrl}/api/cart/customercode?customerCode=${customerCode}&cartId=${cartId}&masterShopId=${masterShopId}`;
  public static GetCartPricing = (globalId: string, cartId: string) =>
    `${baseUrl}/api/users/${globalId}/carts/${cartId}/pricing`;

  public static async handleCreateCart(calculated?: boolean) {
    try {
      const globalId = Cookies.get('globalId') || '';

      const createCartResponse = await apim.createCart('satairb2b', globalId, {
        fields: 'FULL',
      });

      const mappedCart = calculated
        ? mapCart(createCartResponse.data)
        : mapSimpleCart(createCartResponse.data);

      return toLegacyResponse(createCartResponse, {
        Data: objectKeysToPascalCaseRecursive(mappedCart, ['hasFHS']),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleGetCart(settings: AxiosRequestConfig) {
    try {
      const globalId = Cookies.get('globalId') || '';
      const params = settings.params;
      let response;
      let cart;
      let notifications: Notification[] = [];
      let customAction;

      if (params.calculated) {
        // get calculated cart
        response = await apim.getCart('satairb2b', params.cartCode, globalId, { fields: 'FULL' });
        let mappedCart = mapCart(response.data);

        notifications = checkIfRoundingChanged(response.data);

        // if cart has any SellerGroup and is Marketplace
        // then fetch mastershop for each seller
        if (mappedCart.sellerGroup && mappedCart.sellerGroup.length > 0) {
          for (const sellerGroup of mappedCart.sellerGroup) {
            if (
              sellerGroup.key === Seller.Satair.toLowerCase() ||
              sellerGroup.key === Seller.Airbus.toLowerCase()
            ) {
              continue;
            }
            const masterShopResponse = await apim.getMasterShop(
              'satairb2b',
              sellerGroup.key || '',
              {
                fields: 'FULL',
              },
            );
            const leadShopId = getLeadShopId(masterShopResponse?.data?.shops || []);
            const documentsResponse = await apim.getShopDocuments('satairb2b', leadShopId || '', {
              fields: 'FULL',
            });
            const mappedDocuments = extendDocuments(documentsResponse.data, leadShopId);
            const mastershop = handleMasterShopMapping(masterShopResponse.data, mappedDocuments);
            sellerGroup.termsAndPolicies = mastershop.termsAndPolicies;
            sellerGroup.priorityFee = mastershop.priorityFee;
            sellerGroup.logo = mastershop.logo;
          }
        }

        cart = mappedCart;
      } else {
        // get simple cart
        response = await apim.getCurrentMiniCart('satairb2b', params.cartCode, globalId);
        let mappedSimpleCart = mapSimpleCart(response.data);

        cart = mappedSimpleCart;
      }

      // get cart addresses
      if (cart.code && !cart.deliveryAddress) {
        const addressesResponse = await apim.getCartAddresses('satairb2b', cart.code, globalId, {
          fields: 'FULL',
        });
        const mappedAddresses = mapCartAddresses(addressesResponse.data);
        cart.deliveryAddress = mappedAddresses.addresses[0];
      }

      // show modal if cart has been changed
      if (cart.removedCartEntries && cart.removedCartEntries.length > 0) {
        customAction = {
          actionType: 'TriggerModal',
          modalData: { name: 'ModalCartChanged', data: 'list' },
        };
      } else if (cart.cartChangedExternally) {
        const cartChangeNotifications = getCartChangeNotifications(cart as Cart);
        notifications.push(...cartChangeNotifications);
      }

      return toLegacyResponse(response, {
        Data: objectKeysToPascalCaseRecursive(cart, ['hasFHS']),
        ...(notifications.length > 0 && {
          Notification: notifications.map((notification) =>
            objectKeysToPascalCaseRecursive(notification),
          ),
        }),
        ...(customAction && { CustomActions: [objectKeysToPascalCaseRecursive(customAction)] }),
      });
    } catch (error) {
      if (error.error.errors[0].reason === 'notFound') {
        return CartApi.handleCreateCart(settings.params.calculated);
      }

      return legacyErrorHandler(error);
    }
  }

  public static async handleFetchDeliveryAddresses(settings: AxiosRequestConfig) {
    try {
      const globalId = Cookies.get('globalId') || '';
      const shopId = settings.params.shopId;
      let cartId = settings.params.cartCode;

      if (cartId === '|') {
        cartId = 'current';
      }

      const b2bunitResponse = await UserApi.handleGetUserUnit({ id: globalId });
      const b2bunit = b2bunitResponse.data.Data as unknown as B2BUnit;

      const addressesResponse = await apim.getCartAddresses('satairb2b', cartId, globalId, {
        fields: 'FULL',
      });
      const mappedAddresses = mapCartAddresses(addressesResponse.data);

      if (shopId) {
        mappedAddresses.addresses.forEach((address) => {
          if (address.restrictedMasterShopIds && address.restrictedMasterShopIds.includes(shopId)) {
            address.restrictedAddress = true;
          }
        });
      }

      if (mappedAddresses && mappedAddresses.addresses) {
        mappedAddresses.addresses.forEach((address) => {
          address.postalCodeTown = address.postalCode + ' ' + address.town;
        });
      }

      if (b2bunit && mappedAddresses) {
        const deliveryAddressesCart = mappedAddresses.addresses;
        b2bunit.Addresses = deliveryAddressesCart;
        b2bunit.RedAddresses = mappedAddresses.redAddresses;
        b2bunit.BlueAddresses = mappedAddresses.blueAddresses;
      }

      return toLegacyResponse(addressesResponse, {
        Data: objectKeysToPascalCaseRecursive(b2bunit, [
          'customerIdentificationCode',
          'b2BUnitSalesOrganization',
        ]),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleUpdateDeliveryAddresses({
    shopId,
    cartCode,
    addressId,
  }: {
    shopId: string;
    cartCode: string;
    addressId: string;
  }) {
    try {
      const globalId = Cookies.get('globalId') || '';
      let customAction;

      const response = await apim.setCartDeliveryAddressForMasterShop(
        'satairb2b',
        cartCode,
        shopId,
        globalId,
        { addressId, fields: 'FULL' },
      );
      const mappedCart = mapCart(response.data);
      if (mappedCart.removedCartEntries && mappedCart.removedCartEntries.length > 0) {
        customAction = {
          actionType: 'TriggerModal',
          modalData: { name: 'ModalCartChanged', data: 'list' },
        };
      }

      return toLegacyResponse(response, {
        Data: 'Ok',
        ...(customAction && { CustomActions: [objectKeysToPascalCaseRecursive(customAction)] }),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleDeleteCartEntry({
    cartCode,
    entryId,
  }: {
    cartCode: string;
    entryId: number;
  }) {
    try {
      const globalId = Cookies.get('globalId') || '';

      const response = await apim.removeCartEntry('satairb2b', cartCode, entryId, globalId);

      return toLegacyResponse(response, {
        Data: objectKeysToPascalCaseRecursive(response.data),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleCartEntries(settings: AxiosRequestConfig) {
    try {
      const { cartCode, productId, quantity, warehouse, entryId } = settings.params;
      const globalId = Cookies.get('globalId') || '';
      let cartModification;
      let mappedProduct;

      if (productIdIsOffer(productId)) {
        const response = await apim.getOffer('satairb2b', productId);
        const offer = response.data;
        if (offer.product) {
          mappedProduct = getProductWithBaseMapping(offer.product);
        }
      } else {
        const response = await apim.getProductById('satairb2b', productId);
        mappedProduct = getProductWithBaseMapping(response.data);
      }
      const correctedQuantity = setProductQty(mappedProduct!, quantity);

      if (entryId === null || entryId === undefined) {
        // add entry to cart
        const orderEntries = [
          {
            id: productId,
            quantity: correctedQuantity,
            warehouseCode: warehouse || null,
          },
        ] as CartEntryToAdd[];
        const response = await apim.addCartEntries(
          'satairb2b',
          cartCode,
          globalId,
          { orderEntries },
          {
            fields: 'FULL',
          },
        );
        cartModification = mapAddedCartEntries(response.data);
      } else {
        // update or remove existing cart entry
        if (correctedQuantity === 0 || !correctedQuantity) {
          await apim.removeCartEntry('satairb2b', cartCode, entryId, globalId);
          cartModification = mapAddedCartEntries({ cartModifications: [] });
        } else {
          const response = await apim.updateCartEntry(
            'satairb2b',
            cartCode,
            entryId,
            globalId,
            { quantity: correctedQuantity }, // check if i should modify something else here
            {
              fields: 'FULL',
            },
          );
          cartModification = mapAddedCartEntries({ cartModifications: [response.data] });
        }
      }
      const productSku = mappedProduct?.sku || '';
      const { notification, customAction } = getCartFailIndicators(cartModification, productSku);
      const cartResponse = await CartApi.handleGetCart({ params: { cartCode, calculated: true } });
      return toLegacyResponse(cartResponse, {
        Data: cartResponse.data.Data,
        ...(notification && { Notification: [objectKeysToPascalCaseRecursive(notification)] }),
        ...(customAction && { CustomActions: [objectKeysToPascalCaseRecursive(customAction)] }),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleClearCart(settings: AxiosRequestConfig) {
    try {
      const globalId = Cookies.get('globalId') || '';
      const cartId = settings.params.cartCode;

      const response = await apim.deleteCart('satairb2b', cartId, globalId);

      return toLegacyResponse(response, {
        Data: objectKeysToPascalCaseRecursive(response.data),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleFetchWarehouses(productCode: string) {
    try {
      // Fetch plants based on the product code, similar to the .NET example
      const globalId = Cookies.get('globalId') || '';

      const plantsResponse = await apim.plants(
        'satairb2b',
        { productEntries: [{ id: productCode }] },
        {
          fields: 'FULL',
        },
      );

      let plants = plantsResponse.data.entries?.[0].details;

      // Get the cart with specific parameters
      const cartResponse = await apim.getCurrentMiniCart('satairb2b', 'current', globalId);
      const cart = mapSimpleCart(cartResponse.data);

      // Filter for entries on the product in the cart
      const entriesOnProductInCart: MappedCartEntry[] = cart.rawEntries.filter(
        (x: MappedCartEntry) => x.product && x.product.id === productCode,
      );

      // Modify plants if it only has one entry with a null warehouse
      if (plants && plants.length === 1 && !plants[0].warehouse) {
        plants = [];
        const warehouse = entriesOnProductInCart[0]?.warehouse;
        if (warehouse) plants.push({ warehouse });
      }

      // Construct view model (vm)
      const mappedWarehouses = {
        currentQuantity: entriesOnProductInCart.reduce((sum, x) => sum + (x.quantity || 0), 0),
        warehouses: plants
          ?.filter((x) => x.warehouse)
          .map((x) => ({
            hasCartQuantity: false,
            warehouseCode: x.warehouse?.code || '',
            warehouseName: x.warehouse?.name || '',
            warehouseQuantity: x.quantity || 0,
            warehouseInStock: x.inStock || false,
            unit: x.unit?.code || '',
          })),
      };

      if (mappedWarehouses.warehouses && mappedWarehouses.warehouses.length > 0) {
        // Apply cart data to the view model
        const cartData = entriesOnProductInCart
          .map((x) => {
            if (x.warehouse) {
              const warehouse = plants?.find((y) => y.warehouse?.code === x.warehouse?.code);
              return {
                hasCartQuantity: true,
                warehouseCode: x.warehouse.code || '',
                warehouseName: x.warehouse.name || '',
                warehouseQuantity: warehouse?.quantity || 0,
                warehouseInStock: warehouse?.inStock || false,
                entryNumber: x.entryNumber,
                unit: x.product?.unit || '',
                quantity: x.quantity,
              };
            } else {
              return null;
            }
          })
          .filter((x) => x !== null);

        // Update the warehouses with cart data
        cartData.forEach((x) => {
          if (!x || !mappedWarehouses.warehouses) return;

          const idx = mappedWarehouses.warehouses.findIndex(
            (y) => y.warehouseCode === x.warehouseCode,
          );
          if (idx >= 0) {
            mappedWarehouses.warehouses[idx] = x;
          } else {
            mappedWarehouses.warehouses.push(x);
          }
        });
      }

      return toLegacyResponse(plantsResponse, {
        Data: objectKeysToPascalCaseRecursive(mappedWarehouses),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleUpdateWarehouses({
    productCode,
    entryDetailsData,
    calculated,
    deleteEntry,
  }: {
    productCode: string;
    entryDetailsData?: CartEntryDetails[];
    calculated?: boolean;
    deleteEntry?: boolean;
  }) {
    try {
      const globalId = Cookies.get('globalId') || '';
      let entryDetails = entryDetailsData;

      if (!entryDetails) {
        const cartResponse = await apim.getCurrentMiniCart('satairb2b', 'current', globalId);
        const cart = mapSimpleCart(cartResponse.data);
        const productEntries: MappedCartEntry[] = cart.rawEntries.filter(
          (entry: MappedCartEntry) => entry.product && entry.product.id === productCode,
        );

        entryDetails = productEntries.map((entry) => ({
          HasCartQuantity: true,
          WarehouseCode: entry.warehouse?.code,
          WarehouseName: entry.warehouse?.name,
          WarehouseQuantity: entry.quantity,
          EntryNumber: entry.entryNumber,
        }));
      }

      entryDetails?.sort((a, b) => {
        const quantityA = a.Quantity ?? 0;
        const quantityB = b.Quantity ?? 0;
        const entryNumberA = a.EntryNumber ?? 0;
        const entryNumberB = b.EntryNumber ?? 0;

        if (quantityB === quantityA) {
          return entryNumberB - entryNumberA;
        }
        return quantityB - quantityA;
      });

      if (!entryDetails) {
        const cartData = await CartApi.handleGetCart({
          params: { cartCode: 'current', calculated },
        });
        return cartData;
      }

      for (let index = 0; index < entryDetails.length; index++) {
        const entry = entryDetails[index];

        if (entry.EntryNumber != null) {
          if (deleteEntry || entry.Quantity === 0) {
            await apim.removeCartEntry('satairb2b', 'current', entry.EntryNumber, globalId);
          } else {
            await apim.updateCartEntry(
              'satairb2b',
              'current',
              entry.EntryNumber,
              globalId,
              { quantity: entry.Quantity },
              {
                fields: 'FULL',
              },
            );
          }
        } else if (entry.Quantity && entry.Quantity > 0) {
          const orderEntries = [
            {
              id: productCode,
              quantity: entry.Quantity,
              warehouseCode: entry.WarehouseCode,
            },
          ];
          await apim.addCartEntries(
            'satairb2b',
            'current',
            globalId,
            { orderEntries },
            {
              fields: 'FULL',
            },
          );
        }
      }

      const cartData = await CartApi.handleGetCart({ params: { cartCode: 'current', calculated } });
      return cartData;
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }
}
