import { CartApi } from '@/src/core/api';
import router from '@/src/core/router';
import { CancelReqCategory, Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useModalStore } from '@/src/core/stores/modal';
import {
  Address,
  Carrier,
  CartEntry,
  CartPricing,
  OwningSystem,
  ProductAvailable,
  SellerGroup,
} from '@/src/core/types/api';
import { ICart, ICartQueueItem, ISellerGroup } from '@/src/core/types/interfaces';
import { RuleStatus } from '@/src/core/types/rule-status';
import { DisplayDateFormat } from '@/src/core/utils/dates';
import { formatProductPrice } from '@/src/market/services/airbus-parts';
import { mapPricingEntryToCartEntry } from '@/src/market/services/cart-service';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useProductAdditionalInfoStore } from '../stores/product-additional-info';
import { useProductPriceInfoStore } from '../stores/product-price-info';
import { useCheckoutStore } from './checkout';

const AIRBUS_SELLER = 'airbus';

export const useCartStore = defineStore('cart', () => {
  const modalStore = useModalStore();
  const checkoutStore = useCheckoutStore();
  const productAdditionalInfoStore = useProductAdditionalInfoStore();
  const productPriceInfoStore = useProductPriceInfoStore();

  const currentCartCode = ref<string>('current');
  const addToCartQueue = ref<ICartQueueItem[]>([]);
  const isDeleting = ref<boolean>(false);
  const isBusy = ref<boolean>(false);
  const isQueuingCartItems = ref<boolean>(false);
  const currentCart = ref<ICart>({
    Entries: [] as CartEntry[],
    Carrier: {} as Carrier,
    SellerGroup: [] as ISellerGroup[],
  } as ICart);
  const cartPrices = ref<CartPricing | null>(null);

  const isBusyUpdated = (payload: { IsBusy: boolean }) => {
    isBusy.value = payload.IsBusy;
  };

  const isDeletingUpdated = (payload: { IsDeleting: boolean }) => {
    isDeleting.value = payload.IsDeleting;
  };

  const currentCartUpdated = (payload: { cart: ICart; clearQueue?: boolean }) => {
    currentCart.value = {
      ...currentCart.value,
      ...payload.cart,
    };

    if (payload.clearQueue) {
      for (const offer of payload.cart.Entries) {
        addToCartUnqueued({ productId: offer.Id });
      }
    }
  };

  const addPricingToEntries = (entries: CartEntry[]) => {
    return entries.map((entry) => {
      const pricingEntry = entry.HasMultipleWarehouse
        ? getCartPricingMultipleStockLocationsByEntryNumber(entry.AvailabilityList)
        : getCartPricingByEntryNumber(entry.EntryNumber);
      return mapPricingEntryToCartEntry(pricingEntry, entry);
    });
  };

  const cartPricesUpdated = (payload: { cartPrices: CartPricing }) => {
    cartPrices.value = payload.cartPrices;

    if (!cartPrices.value.errorMesage) {
      currentCart.value.TotalPrice = totalPrice.value;
    }

    currentCart.value.SellerGroup = currentCart.value.SellerGroup.map((seller) => {
      if (seller.Key && seller.Key.toLowerCase() === AIRBUS_SELLER) {
        const masterShopInfos = cartPrices.value?.masterShopInfos.find(
          ({ masterShopId }) => masterShopId.toLowerCase() === seller.Key.toLowerCase(),
        );

        return {
          ...seller,
          Entries: addPricingToEntries(seller.Entries),
          Price: formatProductPrice(masterShopInfos?.price),
        };
      }

      return seller;
    });
  };

  const addToCartQueued = (payload: { productId: string; quantity: number }) => {
    const foundIndex = addToCartQueue.value.findIndex(
      (queueItem) => queueItem.productId === payload.productId,
    );

    if (foundIndex > -1) {
      addToCartQueue.value[foundIndex].quantity = payload.quantity;
    } else {
      addToCartQueue.value.push(payload);
    }
  };

  const addToCartUnqueued = (payload: { productId: string }) => {
    const foundIndex = addToCartQueue.value.findIndex(
      (queueItem) => queueItem.productId === payload.productId,
    );

    if (foundIndex > -1) {
      addToCartQueue.value.splice(foundIndex, 1);
    }
  };

  const reset = () => {
    cartPrices.value = null;
    currentCartCode.value = 'current';
    currentCart.value = {
      Entries: [] as CartEntry[],
      Carrier: {} as Carrier,
      DeliveryAddress: {} as Address,
    } as ICart;
    addToCartQueue.value = [];
    isDeleting.value = false;
    isBusy.value = false;
  };

  const fetchCart = async (payload?: { cartCode?: string; calculated?: boolean }) => {
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess, Data } = await Req(
      {
        url: CartApi.GetCart,
        params: {
          cartCode: payload?.cartCode || currentCartCode.value,
          calculated: payload?.calculated || false,
        },
      },
      new ReqQueue(ReqQueueTypes.Default, 'cart', 'getcart'),
      CartApi.handleGetCart,
    );

    if (IsSuccess) {
      currentCartUpdated({ cart: Data });
      const authenticationStore = useAuthenticationStore();

      if (hasAirbusSeller.value && router.currentRoute.value.path.includes('cart')) {
        const globalId = authenticationStore.getGlobalId as string;
        await fetchCartPricing({
          cartId: currentCartCode.value,
          globalId,
        });
      }

      if (payload?.calculated) {
        extractAndUpdateCheckoutData({ sellerGroup: currentCart.value.SellerGroup });
      }

      if (isDeleting.value) {
        isDeletingUpdated({ IsDeleting: false });
      }
    }

    isBusyUpdated({ IsBusy: false });
  };

  const fetchCartPricing = async (payload: { cartId: string; globalId: string }) => {
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess, Data } = await Req({
      url: CartApi.GetCartPricing(payload.globalId, payload.cartId),
    });

    if (IsSuccess) {
      cartPricesUpdated({ cartPrices: Data });
    } else {
      cartPrices.value = null;
    }

    isBusyUpdated({ IsBusy: false });
  };

  const extractAndUpdateCheckoutData = async (payload: { sellerGroup: ISellerGroup[] }) => {
    checkoutStore.resetSellers();
    await payload.sellerGroup.map((sellerItem) => {
      return checkoutStore.addSellerGroup({ seller: sellerItem }).then(() => {
        // @ts-ignore
        delete sellerItem.DeliveryAddress;
        // @ts-ignore
        delete sellerItem.Carrier;
        // @ts-ignore
        delete sellerItem.CarrierInfo;
        // @ts-ignore
        delete sellerItem.CarrierInstructions;
      });
    });
  };

  const queueAddProducts = async () => {
    const addProduct = async (queueIndex?: number, adjustedQty?: number) => {
      isQueuingCartItems.value = true;
      const index = queueIndex || 0;
      let queuedProduct = { ...addToCartQueue.value[index] };

      if (adjustedQty) {
        queuedProduct = Object.assign({}, queuedProduct, {
          quantity: adjustedQty,
        });
      }

      const { IsSuccess, Data } = await Req(
        {
          url: CartApi.CartEntries,
          params: { cartCode: currentCartCode.value, ...queuedProduct },
          headers: queuedProduct.notify ? { Notify: true } : null,
        },
        new ReqQueue(ReqQueueTypes.LastResponse, 'cartAddProduct'),
        CartApi.handleCartEntries,
      );

      if (IsSuccess) {
        const authenticationStore = useAuthenticationStore();
        const cartEntry = Data.Entries.find(
          (item: CartEntry) => item.Id === queuedProduct.productId,
        );
        const cartQty = cartEntry?.Quantity || 0;
        const cartQueueQuantity =
          addToCartQueue.value.find((item) => item.productId === queuedProduct.productId)
            ?.quantity || 0;
        const adjustQuantity = Number(cartQueueQuantity - cartQty);

        // If not found in cart, remove directly from queue
        if (!cartEntry) {
          addToCartUnqueued({
            productId: queuedProduct.productId,
          });
        }

        if (adjustQuantity !== 0 && cartQty >= 1 && adjustQuantity > 0) {
          await addProduct(index, adjustQuantity);
          return;
        }

        currentCartUpdated({ cart: Data, clearQueue: true });

        if (router.currentRoute.value.path.includes('cart') && hasAirbusSeller.value) {
          const globalId = authenticationStore.getGlobalId as string;
          await fetchCartPricing({
            cartId: currentCartCode.value,
            globalId,
          });
        }
      } else {
        // HANDLE ERROR
        // REMOVE FROM QUEUE and CONTINUE
        addToCartUnqueued({
          productId: queuedProduct.productId,
        });
        addOrStop();
        return;
      }
      addOrStop();
    };

    const addOrStop = async () => {
      if (addToCartQueue.value.length > 0) {
        await addProduct();
      } else {
        isQueuingCartItems.value = false;
        if (router.currentRoute.value.path.includes('cart')) {
          await fetchCart({ calculated: true });
        }
        isBusyUpdated({ IsBusy: false });
      }
    };

    if (!isQueuingCartItems.value) {
      await addProduct();
    }
  };

  const addProductToCurrentCart = async (payload: {
    productId: string;
    quantity: number;
    entryId?: number;
    calculated?: boolean;
    notify?: boolean;
    warehouse?: string | null;
  }) => {
    isBusyUpdated({ IsBusy: true });

    if (payload.quantity <= 0) {
      isDeletingUpdated({ IsDeleting: true });
    }

    // Ìf the item exists AND has multiple warehouses THEN open modal + RETURN
    const existingEntry =
      currentCart.value.Entries &&
      currentCart.value.Entries.find(
        (product: CartEntry) => product.Product.Id === payload.productId,
      );

    if (existingEntry && existingEntry.HasMultipleWarehouse) {
      isBusyUpdated({ IsBusy: false });
      modalStore.showModal({
        modalComponent: 'ModalSelectWarehouse',
        params: { productEntry: existingEntry.Product, calculated: true },
        first: true,
      });
      return;
    }
    // ...

    // If the item is new AND NOT calculated, ADD to queue AND return
    const queueItem = !existingEntry && payload.quantity > 0 && !payload.calculated;

    if (queueItem) {
      addToCartQueued({ ...payload });
      await queueAddProducts();

      return;
    }

    const { IsSuccess, Data } = await Req(
      {
        url: CartApi.CartEntries,
        params: { cartCode: currentCartCode.value, ...payload },
        headers: payload.notify ? { Notify: true } : null,
      },
      new ReqQueue(ReqQueueTypes.LastResponse, 'cartQuantity'),
      CartApi.handleCartEntries,
    );

    if (IsSuccess) {
      if (
        !(router.currentRoute.value.name === 'cart' && payload.calculated) &&
        (router.currentRoute.value.path.includes('cart') || payload.calculated)
      ) {
        await fetchCart({ calculated: true });
      } else {
        const authenticationStore = useAuthenticationStore();

        currentCartUpdated({ cart: Data, clearQueue: true });

        if (router.currentRoute.value.path.includes('cart') && hasAirbusSeller.value) {
          const globalId = authenticationStore.getGlobalId as string;
          await fetchCartPricing({
            cartId: currentCartCode.value,
            globalId,
          });
        }
      }
    }

    isDeletingUpdated({ IsDeleting: false });
    isBusyUpdated({ IsBusy: false });
  };

  const cancelChangeCartQuantity = async () => {
    CancelReqCategory('cartQuantity');
  };

  const deleteCartEntry = async (payload: { cartCode?: string; entryId: number }) => {
    cancelChangeCartQuantity();
    isBusyUpdated({ IsBusy: true });
    isDeletingUpdated({ IsDeleting: true });

    const { IsSuccess } = await Req(
      {
        url: CartApi.DeleteCartEntry(payload.cartCode || currentCartCode.value, payload.entryId),
        method: 'DELETE',
      },
      undefined,
      CartApi.handleDeleteCartEntry.bind(CartApi, {
        cartCode: payload.cartCode || currentCartCode.value,
        entryId: payload.entryId,
      }),
    );

    if (IsSuccess) {
      await fetchCart({ calculated: true });
    }

    isBusyUpdated({ IsBusy: false });
    isDeletingUpdated({ IsDeleting: false });

    return { IsSuccess };
  };

  const updateProductQuantity = async (payload: {
    cartCode?: string;
    entryId: string;
    quantity: string;
  }) => {
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess } = await Req({
      url: CartApi.CartProductQuantity(payload.cartCode || currentCartCode.value, payload.entryId),
      data: { quantity: payload.quantity },
      method: 'POST',
    });

    if (IsSuccess) {
      fetchCart();
    }
    isBusyUpdated({ IsBusy: false });
  };

  const updateCarrier = async (payload: {
    sellerId: string;
    cartCode?: string;
    carrierCode: string;
    carrierName: string;
    accountNo?: string;
    carrierInstructions?: string;
    serviceLevelAgreements?: string;
  }) => {
    isBusyUpdated({ IsBusy: true });
    // TODO: Note: This is not a checkoutData Specific Req, but we have sellerId available
    const { IsSuccess } = await Req({
      url: CartApi.UpdateCarrier(payload.cartCode || currentCartCode.value, payload.carrierCode),
      data: {
        sellerId: payload.sellerId,
        accountNo: payload.accountNo,
        carrierName: payload.carrierName,
        additionalInstructions: payload.carrierInstructions,
        serviceLevelAgreements: payload.serviceLevelAgreements,
      },
      method: 'POST',
    });
    isBusyUpdated({ IsBusy: false });
    return { IsSuccess };
  };

  const updatePriority = async (payload: { sellerId: string }) => {
    isBusyUpdated({ IsBusy: true });

    const checkoutData = checkoutStore.seller(payload.sellerId);
    const isAog = checkoutData?.PriorityInfo.Priority.Code === 'AOG';
    const todaysDate = dayjs().format(DisplayDateFormat);
    let date =
      checkoutData && !checkoutData.NamedDeliveryDate && payload.sellerId === AIRBUS_SELLER
        ? todaysDate
        : checkoutData?.NamedDeliveryDate;

    const data = {
      priority: checkoutData?.PriorityInfo.Priority.Code,
      acReg: checkoutData?.PriorityInfo.AcReg,
      ...(!isAog && date && { deadline: dayjs(date).format(DisplayDateFormat) }),
    };

    const { IsSuccess } = await Req({
      url: CartApi.UpdatePriority(currentCartCode.value, payload.sellerId),
      data,
      method: 'POST',
    });

    isBusyUpdated({ IsBusy: false });

    return { IsSuccess };
  };

  const updateAirbusPriorityToRTN = async () => {
    isBusyUpdated({ IsBusy: true });

    const checkoutData = checkoutStore.seller('airbus');
    const { IsSuccess } = await Req({
      url: CartApi.UpdatePriority(currentCartCode.value, 'airbus'),
      data: {
        priority: 'RTN',
        acReg: checkoutData?.PriorityInfo.AcReg,
        deadline: '',
      },
      method: 'POST',
    });

    isBusyUpdated({ IsBusy: false });

    return { IsSuccess };
  };

  const updateDeliveryAddress = async (payload: {
    cartCode?: string;
    sellerId: string;
    addressId: string;
  }) => {
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess } = await Req(
      {
        url: CartApi.UpdateDeliveryAddresses(
          payload.cartCode || currentCartCode.value,
          payload.addressId,
        ),
        params: { shopId: payload.sellerId },
        method: 'POST',
      },
      undefined,
      CartApi.handleUpdateDeliveryAddresses.bind(CartApi, {
        shopId: payload.sellerId,
        cartCode: payload.cartCode || currentCartCode.value,
        addressId: payload.addressId,
      }),
    );

    if (IsSuccess) {
      productPriceInfoStore.reset();
      productAdditionalInfoStore.reset();
    }

    isBusyUpdated({ IsBusy: false });

    return { IsSuccess };
  };

  const updateRemark = async (payload: {
    cartCode: string;
    entryNumher: string;
    remarks: string;
  }) => {
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess } = await Req({
      url: CartApi.UpdateRemark(payload.cartCode, payload.entryNumher),
      data: {
        cartCode: payload.cartCode,
        entryNumber: payload.entryNumher,
        remarks: payload.remarks,
      },
      method: 'PATCH',
    });

    isBusyUpdated({ IsBusy: false });

    return { IsSuccess };
  };

  const clearCart = async (payload?: { cartCode?: string }) => {
    isBusyUpdated({ IsBusy: true });
    isDeletingUpdated({ IsDeleting: true });

    const { IsSuccess } = await Req(
      {
        url: CartApi.ClearCart,
        params: { cartCode: payload?.cartCode || currentCartCode.value },
      },
      undefined,
      CartApi.handleClearCart,
    );

    if (IsSuccess) {
      reset();
      await fetchCart();
    }

    isBusyUpdated({ IsBusy: false });

    return { IsSuccess };
  };

  const deleteMultiEntries = async (payload: { productCode: string }) => {
    isBusyUpdated({ IsBusy: true });
    const { IsSuccess } = await Req(
      {
        method: 'POST',
        url: CartApi.UpdateWarehouses(payload.productCode, true),
        data: { delete: true },
      },
      undefined,
      CartApi.handleUpdateWarehouses.bind(CartApi, {
        productCode: payload.productCode,
        calculated: true,
        deleteEntry: true,
      }),
    );

    if (IsSuccess) {
      fetchCart({ calculated: true });
    }

    isBusyUpdated({ IsBusy: false });
  };

  const setCart = async (payload: { cart: ICart }) => {
    currentCartUpdated({ cart: payload.cart });
  };

  const seller = (sellerKey: string) => {
    return currentCart.value.SellerGroup?.find((sellerItem) => sellerItem.Key === sellerKey);
  };

  const getCartEntry = (productId: string) => {
    return (
      currentCart.value.SellerGroup?.map((entry) =>
        entry.Entries?.find((product) => product.Id === productId),
      ).filter((x) => !!x)[0] || ({} as CartEntry)
    );
  };

  const getCartPricingByEntryNumber = (entryNumber: number) => {
    return cartPrices.value?.entries.find((entry) => entry.entryNumber === entryNumber);
  };

  const getCartPricingMultipleStockLocationsByEntryNumber = (locations: ProductAvailable[]) => {
    const entryNumbers = locations.map((location) => location.EntryNumber);
    return cartPrices.value?.entries.filter((pricing) =>
      entryNumbers.includes(pricing.entryNumber),
    );
  };

  const sellers = computed(() => {
    return currentCart.value.SellerGroup;
  });

  // This is still safe to use according to William
  const sellersWithMinOrderValueNotMet = computed(() => {
    const sellersGroupMOV: SellerGroup[] = [];
    currentCart.value.SellerGroup.forEach((element) => {
      if (element.HasMinOrderValue && element.MinOrderValue > Number(element.Price.Value)) {
        sellersGroupMOV.push(element);
      }
    });
    return sellersGroupMOV;
  });

  const hasPriceNotAvailable = computed(() => {
    if (hasAirbusSeller.value && cartPrices.value) {
      return !cartPrices.value?.entries.every(({ priceAvailable }) => priceAvailable);
    }

    return !currentCart.value?.Entries.every(({ PriceAvailable }) => PriceAvailable);
  });

  const entriesAvailableForCheckout = computed(() => {
    return currentCart.value.Entries.every(
      (entry) =>
        entry.PriceAvailable ||
        (entry.Product.OwningSystem === OwningSystem.BLUE &&
          entry.Product.ProductRule.Sellable === RuleStatus.YES),
    );
  });

  const totalPrice = computed(() => {
    if (hasAirbusSeller.value && cartPrices.value) {
      return formatProductPrice(cartPrices.value.totalPrice);
    }

    return currentCart.value.TotalPrice;
  });

  const hasAirbusSeller = computed(() => {
    return currentCart.value.SellerGroup.some((seller) => seller.Key === AIRBUS_SELLER);
  });

  // TODO: Use this after the Vue 3 upgrade when useRouter is safe to use
  const isCartRoute = computed(() => {
    return router.currentRoute.value.path.includes('cart');
  });

  return {
    addToCartQueue,
    cartPrices,
    currentCart,
    currentCartCode,
    entriesAvailableForCheckout,
    isBusy,
    isDeleting,
    sellers,
    sellersWithMinOrderValueNotMet,
    hasPriceNotAvailable,
    totalPrice,
    seller,
    getCartEntry,
    reset,
    fetchCart,
    fetchCartPricing,
    extractAndUpdateCheckoutData,
    queueAddProducts,
    addProductToCurrentCart,
    deleteCartEntry,
    updateProductQuantity,
    updateCarrier,
    updatePriority,
    updateAirbusPriorityToRTN,
    updateDeliveryAddress,
    updateRemark,
    clearCart,
    deleteMultiEntries,
    setCart,
  };
});
