import {
  ImportProductListStatus,
  User,
  UserProductList,
  UserProductListEntry,
  UserProductListSearchPage,
} from '@/src/core/apim';
import { getOfferWithMapping, legacyEmptyOffer } from '@/src/market/services/legacy-mapping-offer';
import { mapPrice } from '@/src/market/services/legacy-mapping-price';
import { getProductWithBaseMapping } from '@/src/market/services/legacy-mapping-product';
import { getFriendlyRoles } from '@/src/market/services/user-service';

export const removeUtilityLists = (lists: UserProductList[]) => {
  const utilityListNames = ['Multiline search', 'Create quote'];
  const withoutUtilityLists = lists.filter((list) => !utilityListNames.includes(list.name!));

  return withoutUtilityLists;
};

export const getRecentLists = (lists: UserProductList[]) => {
  const count = 6;
  const orderKey = 'lastModifiedDate';
  const recent = [...lists]
    .sort((a, b) => {
      const dateA = new Date(a[orderKey]!).getTime();
      const dateB = new Date(b[orderKey]!).getTime();

      return dateB - dateA;
    })
    .slice(0, count);

  return recent;
};

export const mapListsWithPagination = (
  data: UserProductListSearchPage,
  mappedLists: UserProductList[],
) => {
  return {
    ...data,
    productLists: mappedLists,
    recentProductLists: getRecentLists(mappedLists),
    count: data.pagination?.totalResults,
    page: data.pagination?.currentPage,
    totalPages: data.pagination?.totalPages,
    facets: data.facets || [],
  };
};

export const mapLists = (lists: UserProductList[]) => {
  return lists.map((list) => mapList(list));
};

export const mapList = (list: UserProductList) => {
  return {
    ...list,
    createdBy: mapCreatedBy(list.createdBy!),
    unresolvedEntries: list.unresolvedEntries || [],
    entries: mapListEntries(list.entries || []),
  };
};

export const mapCreatedBy = (createdBy: User) => {
  return {
    ...createdBy,
    userRoles: getFriendlyRoles(createdBy.roles!),
    unit: {
      addresses: [],
      companies: [],
      billingAddress: {
        defaultAddress: false,
        shippingAddress: false,
        visibleInAddressBook: false,
        billingAddress: false,
        vatId: '',
        vatID: '',
        defaultBillingAddress: false,
        defaultPayerAddress: false,
        defaultShippingAddress: false,
        payerAddress: false,
        soldtoAddress: false,
        restrictedAddress: false,
        restrictedMasterShopIds: [],
        exportBlocked: false,
        eusRequired: false,
      },
      vatId: '',
      vatID: '',
      readOnlyAccount: false,
      exportBlocked: false,
      eusRequired: false,
      requiresCashInAdvance: false,
      forwarderInformationRequired: false,
      blueAddresses: [],
      redAddresses: [],
      payerAddressIsSameAsBilling: false,
    },
    isAdmin: createdBy.roles ? createdBy.roles.includes('b2badmingroup') : false,
    isReadOnly: createdBy.readOnly,
    isFinance: createdBy.financeCustomer || false,
    isFirstTime: !createdBy.confirmedInformation,
    linkedAccounts: [],
    lastLoginNoDays: getLastLoginDays(createdBy.lastLogin!),
  };
};

export const getLastLoginDays = (lastLogin: string) => {
  if (lastLogin && lastLogin.trim() !== '') {
    const dt = new Date(lastLogin);
    const valid = !isNaN(dt.getTime());

    if (valid) {
      const now = new Date();
      const differenceInTime = now.getTime() - dt.getTime();
      const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
      return differenceInDays.toString();
    }
  }
  return '0';
};

export const isListNameValid = (listName: string) => {
  const listNamePattern = /^[a-zA-Z0-9\s]*$/;

  if (
    listName === 'Multiline search' ||
    listName === 'Create quote' ||
    !listNamePattern.test(listName)
  ) {
    return false;
  }

  return true;
};

export const listNameInvalidResponse = (listName: string) => {
  let message = `${listName} is a reserved name`;

  const listNamePattern = /^[a-zA-Z0-9\s]*$/;
  if (!listNamePattern.test(listName)) {
    message = 'Special characters are not allowed';
  }

  throw {
    error: {
      errors: [
        {
          reason: 'List name not allowed',
          message,
        },
      ],
    },
  };
};

export const mapListEntries = (entries: UserProductListEntry[]) => {
  return entries.map((entry) => {
    const offer = entry.offer ? getOfferWithMapping(entry.offer) : legacyEmptyOffer;
    const product = entry.product
      ? getProductWithBaseMapping(entry.product)
      : entry.offer
        ? (offer as any).product
        : undefined;

    return {
      ...entry,
      id: entry.product?.id || entry.offer?.id,
      product,
      offer,
      basePrice: mapPrice(),
      totalPrice: mapPrice(),
      state: entry.offer?.state || '',
      containsUnknownMaterial: false,
      offeredTotalPrice: mapPrice(),
      offeredBasePrice: mapPrice(),
    };
  });
};

export const writeToCsv = (
  data: { partNumber: string; quantity: string; cageNumber: string }[],
): Uint8Array => {
  // Manually convert the data to CSV format
  const csvContent = data
    .map((item) => `${item.partNumber},${item.cageNumber},${item.quantity}`)
    .join('\n');

  // Convert CSV string to a Uint8Array (bytes)
  const encoder = new TextEncoder();
  const bytes = encoder.encode(csvContent);

  return bytes; // Return as byte array
};

export const mapListImport = (item: ImportProductListStatus, correlationId: string) => {
  return {
    ...item,
    listCode: item.productList?.code,
    productCount: item.productList?.entries?.length,
    correlationId,
  };
};
