import { apim } from '@/src/core/apim';
import { getEnv } from '@/src/core/services/environment';
import { SATAIR_BASESITE_ID } from '../settings/apim';
import { legacyErrorHandler } from '../services/error-notification-handling';
import { toLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { AxiosRequestConfig } from 'axios';
import { ISearchList } from '@/src/core/types/interfaces';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import { SearchService } from '@/src/market/services/search-service';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class SearchApi {
  public static SearchQuery = `${baseUrl}/api/search`;
  public static OfferSearchQuery = `${baseUrl}/api/offersearch`;

  public static async handleOfferSearchQuery(settings: AxiosRequestConfig) {
    const params = settings.params;

    const requestParams = {
      facet: params.facets,
      currentPage: params.currentPage || 0,
      pageSize: params.pageSize || 20,
      sort: params.sort,
      q: params.newQuery,
    };

    try {
      const response = await apim.searchOffers1(SATAIR_BASESITE_ID, requestParams);
      const mappedDataCamelCase = SearchService.mapOfferSearchPageToProductSearch(response.data);

      return toLegacyResponse(response, {
        Data: objectKeysToPascalCaseRecursive(mappedDataCamelCase, [
          'hasFHS',
        ]) as unknown as ISearchList,
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }
}
