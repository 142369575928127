import { FEATURES, hasFeature } from '@/src/core/services/features';
import { useQuotesStore } from '@/src/market/stores/quotes';
import { useUserStore } from '@/src/profile/stores/user';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export const repairExchangeBeforeEnterFeatureCheck = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const userStore = useUserStore();

  const checkOrders = () => {
    if (
      !userStore.blueCustomer ||
      ((to.name === 'repairExchangeOrder' ||
        to.name === 'repairExchangeOrderProduct' ||
        to.name === 'repairExchangeOrderOffer') &&
        !hasFeature(FEATURES.REPAIR_EXCHANGE_SERVICE_ORDERS))
    ) {
      next({
        name: 'orders',
      });
    } else {
      next();
    }
  };

  if (userStore.blueCustomer === undefined) {
    userStore.fetchUserProfile().finally(() => {
      checkOrders();
    });
  } else {
    checkOrders();
  }
};

export const leaseOrderBeforeEnterFeatureCheck = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const userStore = useUserStore();

  const checkOrders = () => {
    if (
      !userStore.blueCustomer ||
      ((to.name === 'leaseOrder' ||
        to.name === 'leaseOrderProduct' ||
        to.name === 'leaseOrderOffer') &&
        !hasFeature(FEATURES.LEASE_SERVICE_ORDERS))
    ) {
      next({
        name: 'orders',
      });
    } else {
      next();
    }
  };

  if (userStore.blueCustomer === undefined) {
    userStore.fetchUserProfile().finally(() => {
      checkOrders();
    });
  } else {
    checkOrders();
  }
};

export const quotesBeforeEnterFeatureCheck = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const userStore = useUserStore();
  const quotesStore = useQuotesStore();

  const checkQuotes = () => {
    if (quotesStore.showQuotes) {
      next();
    } else {
      next({ name: 'search' });
    }
  };

  if (quotesStore.showQuotes === undefined) {
    userStore.fetchUserProfile().finally(() => {
      checkQuotes();
    });
  } else {
    checkQuotes();
  }
};
