import { MiniCart } from '@/src/core/apim';
import { mapCart, mapCartSellerGroup } from '@/src/market/services/legacy-mapping-cart';

export const mapSimpleCart = (cart: MiniCart) => {
  const mappedCart = mapCart(cart);
  const isFull = false;
  const priorityInfo = {
    priority: {
      code: '',
      name: '',
    },
  };
  const removedCartEntries = [];
  const masterShopInfos = cart.masterShopInfos || [
    {
      masterShopId: '',
    },
  ];
  const sellerGroup = mapCartSellerGroup({ ...cart, masterShopInfos });

  return {
    ...mappedCart,
    isFull,
    priorityInfo,
    removedCartEntries,
    sellerGroup,
  };
};
