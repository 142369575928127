import { OfferInfo, OrderEntry } from '@/src/core/apim';
import { DateFormat, DateFormatUTC } from '@/src/core/utils/dates';
import { handleMasterShopMapping } from '@/src/market/services/legacy-mapping-mastershop';
import {
  ProductWithMapping,
  getProductWithMapping,
} from '@/src/market/services/legacy-mapping-product';
import { MASTERSHOP_ID } from '@/src/market/settings/mastershop';

enum StockStatus {
  Ready,
  Partly,
  OutOfStock,
}

const orderableStatusValues = [
  'limitedbycrossplantmaterialstatus',
  'nopriceavailable',
  'noquantityremaining',
];

const CANCELLED = 'cancelled';
const ASM_HIDDEN = 'ASM_HIDDEN';

export const mapOrderEntryToCartEntry = (e: OrderEntry) => {
  const availabilityList = getAvailabilityList(e);
  const product = getEntryProduct(e);
  const cancelled = Boolean(e.cancelReasonCode);
  const statusDisplay = cancelled ? CANCELLED : '';
  const hasMinOrderLineValue = checkIfEntryHasMinOrderLineValue(e);
  const hasMinOrderValue =
    e.masterShop?.minimumOrderValueUsd && e.masterShop?.minimumOrderValueUsd > 0;
  const id = e.offerInfo?.id ?? e.product?.id;
  const entryIsCancelled = checkIfEntryIsCancelled(e);
  const unknownDeliveryDate = getUnknownDeliveryDate(e);
  const enableCustomizeWarehouse = true;
  const orderable =
    e.orderableStatus && orderableStatusValues.includes(e.orderableStatus?.toLowerCase());
  const salesUnit = e.salesUnit?.code;
  const slowMoverPriceAvailable = Boolean(e.zproPriceAvailable);
  const stockStatus = StockStatus.Ready;
  const isSatairProduct = e.masterShop?.id === MASTERSHOP_ID.SATAIR;
  const state = e.offerInfo?.state;
  const warehouse = getWarehouse(e);
  const availabilityIndicator = getAvailabilityIndicator(availabilityList);
  const masterShop = handleMasterShopMapping(e.masterShop);
  const offerInfo = extendOfferInfo(e.offerInfo);
  const basePrice = { ...e.basePrice, isAsmHidden: attachAsmHidden(e.infoStatus) };
  const totalPrice = { ...e.totalPrice, isAsmHidden: attachAsmHidden(e.infoStatus) };
  const priceUnit = e.priceUnit ? e.priceUnit.code : e.salesUnit?.code;

  return {
    ...e,
    availabilityList,
    id,
    hasMinOrderLineValue,
    hasMinOrderValue,
    unknownDeliveryDate,
    entryIsCancelled,
    orderable,
    enableCustomizeWarehouse,
    salesUnit,
    slowMoverPriceAvailable,
    stockStatus,
    isSatairProduct,
    state,
    warehouse,
    availabilityIndicator,
    product,
    statusDisplay,
    cancelled,
    masterShop,
    basePrice,
    totalPrice,
    offerInfo,
    priceUnit,
  };
};

export type MappedOrderEntry = ReturnType<typeof mapOrderEntryToCartEntry>;

export const getAvailabilityList = (e: OrderEntry) => {
  let availabilities = [];

  if (!e.availabilityInfos || e.availabilityInfos.length === 0) {
    return;
  }

  if (e.inStock && e.availabilityInfos.length === 1) {
    const warehouse = e.availabilityInfos[0].warehouse ?? e.warehouse;
    const unknownDeliveryDate = getUnknownDeliveryDate(e);
    const isAsmHidden = attachAsmHidden(e.infoStatus);
    const entryNumber = 0;

    return [
      {
        warehouse,
        inStock: true,
        stockCount: e.availabilityInfos[0].quantity,
        availableDate: e.availabilityInfos[0].availabilityDate
          ? DateFormatUTC(e.availabilityInfos[0].availabilityDate)
          : '',
        infoStatus: '',
        unknownDeliveryDate,
        isAsmHidden,
        entryNumber,
      },
    ];
  }

  if (e.availabilityInfos.length === 1 && e.availabilityInfos[0].quantity === 0) {
    return [];
  }

  availabilities = e.availabilityInfos
    .filter(({ quantity }) => quantity && quantity > 0)
    .map((a) => {
      const unknownDeliveryDate = getUnknownDeliveryDate(a);
      const availableDate = getAvailableDate(a.availabilityDate, unknownDeliveryDate);
      const warehouse = a.warehouse ? a.warehouse : getWarehouse(e);
      const isAsmHidden = attachAsmHidden(e.infoStatus);

      return {
        warehouse,
        availableDate,
        inStock: false,
        infoStatus: '',
        stockCount: a.quantity,
        isAsmHidden,
        unknownDeliveryDate,
      };
    });

  const index = availabilities.findIndex(Boolean);

  if (e.inStock && index !== -1) {
    availabilities[index].inStock = true;
  }

  return availabilities;
};

type AvailabilityListMapping = ReturnType<typeof getAvailabilityList>;

const getWarehouse = (entry: OrderEntry) => {
  const locationName = entry.offerInfo?.locationDisplayName
    ? {
        name: entry.offerInfo.locationDisplayName,
      }
    : null;
  return entry.warehouse ? entry.warehouse : locationName;
};

const getAvailabilityIndicator = (availabilityList: AvailabilityListMapping) => {
  const firstAvailability = availabilityList?.[0];
  const availableDate = availabilityList?.[0]?.availableDate;
  const stockCount = firstAvailability?.stockCount;

  if (availableDate && isValidDate(availableDate) && stockCount && stockCount > 0) {
    return availabilityList.length > 1
      ? `From ${DateFormat(availableDate)}`
      : DateFormat(availableDate);
  }

  return 'N/A';
};

export const getUnknownDeliveryDate = (entry: OrderEntry) =>
  entry.infoStatus === 'UNKNOWN_DELIVERYDATE';

export const checkIfEntryHasMinOrderLineValue = (entry: OrderEntry) =>
  Boolean(entry.infoStatus === 'MINIMUM_ORDER_LINE_VALUE');

const isValidDate = (dateString?: string): Boolean => {
  if (!dateString) {
    return false;
  }

  const date = new Date(dateString);

  return date instanceof Date && !isNaN(date.getTime());
};

const getAvailableDate = (availabilityDate?: string, unknownDeliveryDate = false) => {
  return !unknownDeliveryDate && availabilityDate
    ? DateFormat(availabilityDate)
    : new Date().toDateString();
};

const checkIfEntryIsCancelled = (entry: OrderEntry) => {
  return (
    (entry.orderableStatus?.toLowerCase() === 'cancelled' ||
      Boolean(entry.cancelReasonCode) ||
      Boolean(entry.cancelReason)) &&
    Boolean(entry.orderableStatus)
  );
};

export const attachAsmHidden = (infoStatus?: string) => {
  return Boolean(infoStatus === ASM_HIDDEN);
};

const extendOfferInfo = (offerInfo?: OfferInfo) => ({
  ...offerInfo,
  discountEndDateAvailable: getDiscountEndDateAvailable(offerInfo?.discountEndDate),
  discountPriceAvailable: discountPriceAvailable(offerInfo),
  orderedWithDiscountPrice: Boolean(offerInfo?.discountPrice),
  packageQuantity: offerInfo?.packageQuantity ?? 1,
});

const getDiscountEndDateAvailable = (discountEndDate?: string) => {
  const MIN_DATE = new Date(1, 0, 1, 0, 0, 0, 0).getTime();
  return !!discountEndDate && Date.parse(discountEndDate) > MIN_DATE;
};

const discountPriceAvailable = (offerInfo?: OfferInfo) => {
  const { discountPrice, discountStartDate, discountEndDate } = offerInfo ?? {};

  if (!discountPrice || !discountStartDate || !discountEndDate) {
    return false;
  }

  const discountStartDateObj = new Date(discountStartDate);
  const discountEndDateObj = new Date(discountEndDate);

  return (
    (discountStartDateObj <= new Date() || discountStartDateObj.getTime() === 0) &&
    (discountEndDateObj > new Date() || discountEndDateObj.getTime() === 0)
  );
};

const getPackageQuantity = (entry?: OrderEntry, product?: ProductWithMapping, isSatair = false) => {
  return !isSatair && entry?.offerInfo?.packageQuantity && entry?.offerInfo?.packageQuantity > 1
    ? entry?.offerInfo?.packageQuantity
    : product?.packageQuantity;
};

const getAddToBasketQty = (entry?: OrderEntry, product?: ProductWithMapping, isSatair = false) => {
  return !isSatair &&
    entry?.offerInfo?.minimumOrderQuantity &&
    entry?.offerInfo?.minimumOrderQuantity > 1
    ? entry?.offerInfo?.minimumOrderQuantity
    : product?.addToBasketQty;
};

const getCartStep = (entry?: OrderEntry, product?: ProductWithMapping, isSatair = false) => {
  return !isSatair && entry?.offerInfo?.packageQuantity && entry?.offerInfo?.packageQuantity > 1
    ? entry?.offerInfo?.packageQuantity
    : product?.cartStep;
};

const getEntryProduct = (entry?: OrderEntry, isSatair = false) => {
  const product = entry?.product && getProductWithMapping(entry?.product, entry);
  const packageQuantity = getPackageQuantity(entry, product, isSatair);
  const addToBasketQty = getAddToBasketQty(entry, product, isSatair);
  const cartStep = getCartStep(entry, product, isSatair);

  return { ...product, packageQuantity, addToBasketQty, cartStep };
};
