import {
  BaseOwningSystem,
  IOrderListQuery,
  ISearchResultId,
  Seller,
} from '@/src/core/types/interfaces';
import {
  Facet,
  OfferSearch,
  OfferSearchPage,
  FacetValue,
  CertificateType,
} from '@/src/core/apim/spec';
import { addCommerceCDNtoUrl } from './legacy-mapping';
import {
  getCertificateFriendlyName,
  getFriendlyName,
  getOfferUrl,
  populateCertificates,
} from '../utils/offer-search';

export class SearchService {
  /**
   * @param  {IOrderListQuery} data
   */
  public static escape(data: IOrderListQuery) {
    const escapedFilters = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const value = data[key];

        if (value != null && typeof value === 'string') {
          escapedFilters[key] = value.replace(/:/g, '\\:');
        } else {
          escapedFilters[key] = value;
        }
      }
    }
    return escapedFilters;
  }

  /**
   * Maps an offer search item to a product search item.
   *
   * @param {OfferSearch} offer - The offer search item to map.
   * @returns {Object} The mapped product search item.
   */
  public static mapOfferSearchToProductSearchItem(offer: OfferSearch) {
    const isSatairOrAirbusMasterShop =
      offer.owningSystem?.toLowerCase() === BaseOwningSystem.Blue ||
      offer.owningSystem?.toLowerCase() === BaseOwningSystem.Red;
    const isZtel = offer.manufacturerData?.cageCode === 'C1572' && isSatairOrAirbusMasterShop;

    const result = {
      priceHidden: offer.priceHidden || false,
      allowQuoteRequests: offer.allowQuoteRequests || false,
      id: offer.id || '',
      code: offer.id || '',
      materialNumberExternal: offer.materialNumberExternal || '',
      offerUrl: getOfferUrl(offer),
      marketOfferUrl: `/market/product${getOfferUrl(offer)}`,
      name: offer.name || '',
      manufacturerAid: offer.manufacturerAid || '',
      productManufacturer: {
        cageCode: offer.manufacturerData?.cageCode || '',
        name: offer.manufacturerData?.name || '',
      },
      sku: `${offer.manufacturerAid}:${offer.manufacturerData?.cageCode}`,
      certificate: getCertificateFriendlyName(offer.certificateType ?? null),
      isAirbusHazmat: offer.isAirbusHazmat || false,
      hazmatCode:
        offer.hazmat && offer.hazmat.code ? `UN${getFriendlyName(offer.hazmat.code)}` : 'N/A',
      hazmatDescription:
        offer.hazmat && offer.hazmat.name ? getFriendlyName(offer.hazmat.name) : 'N/A',
      hasHazmat: !!offer.hazmat,
      hasCertificate: !!offer.certificates?.length,
      packageQuantity: offer.quantityStep || 1, // correct?
      addToBasketQty: offer.minimumOrderQuantity || 1,
      cartStep: offer.quantityStep || 1,
      interchangeable: offer.partNumberInterchangeableMatch || false,
      airbusProprietary: offer.airbusProprietary || false,
      origin: offer.origin || '',
      isMarketplace: offer.origin === 'MARKETPLACE',
      supplierDisplay:
        offer.owningSystem?.toLowerCase() === BaseOwningSystem.Blue
          ? Seller.Airbus
          : offer.owningSystem?.toLowerCase() === BaseOwningSystem.Red
            ? Seller.Satair
            : offer.masterShop?.name || Seller.Satair,
      partNumberInterchangeableMatch: offer.interchangeableMatchedPartNumber || false,
      state: offer.state || '',
      certificates: populateCertificates(offer.certificates ?? [], offer.certificateType ?? null),
      isZtel: isZtel,
      supplierLogoUrl: offer.masterShop?.searchResultLogo || '',
      supplierLogoCdnUrl: offer.masterShop?.searchResultLogo
        ? addCommerceCDNtoUrl(offer.masterShop.searchResultLogo)
        : '',
      showSupplierLogo: !!offer.masterShop?.searchResultLogo,
      newSupplierLogoUrl: offer.masterShop?.newSearchResultLogo || '',
      newSupplierLogoCdnUrl: offer.masterShop?.newSearchResultLogo
        ? addCommerceCDNtoUrl(offer.masterShop.newSearchResultLogo)
        : '',
      showNewSupplierLogo: !!offer.masterShop?.newSearchResultLogo,
      flammable: offer.flammable || false,
      owningSystem: offer.owningSystem || 'INTERNAL',
      enginePart: offer.enginePart || false,
      isTool: offer.isTool || false,
      serialNumberRequired: offer.serialNumberRequired || false,
      hasFHS: offer.hasFHS || false,
      satairInterchangeables: offer.satairInterchangeables || undefined,
      airbusCertificateForSalesNew: offer.airbusCertificateForSalesNew || undefined,
      images: [], // TODO: The SapCC doesn't have any images, even though backend presents it
      hasImage: false,
      roundingValue: 0, // TODO: SapCC and Backend not aligned
    };

    // empty properties should not be sent to the client
    if (!offer.satairInterchangeables) {
      delete result.satairInterchangeables;
    }

    if (!offer.airbusCertificateForSalesNew) {
      delete result.airbusCertificateForSalesNew;
    }

    return result;
  }

  public static facetsListOptions: { [key: string]: { displayName: string } } = {
    manufacturerName: { displayName: 'Manufacturers' },
    manufacturer: { displayName: 'Manufacturers' },
    state: { displayName: 'Condition' },
    masterShopId: { displayName: 'Sellers' },
    Certificates: { displayName: 'Certificates' },
  };

  public static mapFacet(facet: Facet) {
    const options = facet.name ? SearchService.facetsListOptions[facet.name] : undefined;
    const displayName = options ? options.displayName : facet.name;

    const values = (facet.values ?? [])
      .map(SearchService.mapFacetValue)
      .sort((a, b) => (b.selected ? 1 : -1) - (a.selected ? 1 : -1));

    if (displayName === 'Manufacturers') {
      values.forEach((value) => {
        if (value.name && displayName === 'Manufacturers') {
          try {
            // name can be a JSON object
            const jsonObject = JSON.parse(value.name);
            value.name = jsonObject?.cageCode;
            if (jsonObject?.name) {
              value.subLabel = jsonObject?.name;
            }
          } catch (e) {
            console.error('Error parsing JSON:', e);
          }
        }
      });
    }

    return {
      multiSelect: facet.multiSelect,
      name: facet.name,
      displayName: displayName,
      values: values,
    };
  }

  public static mapFacetValue(facetValue: FacetValue) {
    const queryValue = facetValue.query?.query?.value;
    const result: any = {
      count: facetValue.count,
      name: facetValue.name,
      subLabel: facetValue.subLabel || undefined,
      selected: facetValue.selected,
      code: facetValue.code,
      Query: {
        Url: facetValue.query?.url ?? '',
        Query: {
          Value: queryValue,
        },
      },
    };

    if (facetValue.subLabel) {
      delete result.subLabel;
    }

    return result;
  }

  /**
   * filter out unwanted facets
   */
  public static handleFacets(facets: Facet[] | null) {
    if (facets) {
      facets = facets.filter(
        (x) => x.name && SearchService.facetsListOptions.hasOwnProperty(x.name),
      );
      facets = facets.filter((x) => x.values && x.values.length > 0);
    }

    return facets;
  }

  public static mapOfferSearchPageToProductSearch(data?: OfferSearchPage) {
    const products = data?.offers?.map(SearchService.mapOfferSearchToProductSearchItem) ?? [];
    const searchResultIds: ISearchResultId[] = products.map((p) => ({ Sku: p.sku, Id: p.id }));
    const mappedFacets = data?.facets?.map(SearchService.mapFacet) || [];

    return {
      products: products,
      count: data?.pagination?.totalResults || 0,
      page: data?.pagination?.currentPage || 0,
      totalPages: data?.pagination?.totalPages || 0,
      facets: SearchService.handleFacets(mappedFacets),
      warningEnabled: false, //missing?
    };
  }
}
