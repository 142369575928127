import { CartModification, CartModificationList, OrderEntry } from '@/src/core/apim';
import { getFailIndicator } from '@/src/market/services/legacy-mapping-cart-modification';
import { getProductWithBaseMapping } from '@/src/market/services/legacy-mapping-product';

export enum FailIndicators {
  Full,
  Partial,
  None,
  IncorrectCurrency,
  MultipleOffers,
  MaxAllowedQuantityReached,
  InActiveOfferRemoved,
}

export type MappedCartEntries = ReturnType<typeof mapAddedCartEntries>;

export const mapAddedCartEntries = (list: CartModificationList) => {
  const cartModifications = list.cartModifications && mapCartModifications(list.cartModifications);

  return {
    ...list,
    cartModifications,
    failCount: list.cartModifications ? checkFailCount(cartModifications) : 0,
    failIndicator: list.cartModifications && getFailIndicator(cartModifications),
    inActiveOfferRemoved: false,
  };
};

export const mapCartModifications = (cartModifications: CartModification[]) => {
  return cartModifications.map((item) => mapCartModificationValue(item));
};

export const mapCartModificationValue = (cartModification: CartModification) => {
  return {
    ...cartModification,
    successAdded: Boolean(
      cartModification.quantityAdded && Math.abs(cartModification.quantityAdded) > 0,
    ),
    entry: cartModification.entry ? [mapCartModificationEntry(cartModification.entry)] : [],
  };
};

export const mapCartModificationEntry = (entry: OrderEntry) => {
  return {
    ...entry,
    product: entry.product && getProductWithBaseMapping(entry.product),
    cartEntryRoundedFromSap: entry.changeReasons?.some(
      (reason) => reason.reason === 'quantityRoundedBySap',
    ),
  };
};

const checkFailCount = (cartModifications: CartModification[]) => {
  return cartModifications.filter((item) => item.quantityAdded === 0).length;
};
