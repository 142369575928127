<template>
  <div class="select-warehouse-modal">
    <StandardDialogModal
      :size="uiSize.Large"
      :has-cancel-button="!isReadOnly ? true : false"
      :loading="isBusy"
    >
      <template #header>
        <h3 class="select-warehouse-modal__title">
          <span class="select-warehouse-modal__title__number"
            >{{ product && product.ManufacturerAid }}:<wbr /><span
              class="select-warehouse-modal__title__cagecode"
              >{{
                product && product.ProductManufacturer && product.ProductManufacturer.CageCode
              }}</span
            ></span
          >
        </h3>
        <p class="select-warehouse-modal__name">
          {{ product && product.Name && product.Name.length > 0 ? product.Name : '-' }}
        </p>
      </template>
      <template #body>
        <TransitionExpand>
          <div class="select-warehouse-modal__body" v-if="!isBusy">
            <div class="select-warehouse-modal__specs">
              <div class="select-warehouse-modal__specs__spec" v-if="warehouses.CurrentQuantity">
                <span class="select-warehouse-modal__info__label">{{
                  textK('UI_CART_WAREHOUSE_CURRENT_QTY')
                }}</span>
                <span class="select-warehouse-modal__info__value">{{
                  warehouses.CurrentQuantity
                }}</span>
              </div>
              <div class="select-warehouse-modal__specs__spec" v-if="minQty > 1">
                <span class="select-warehouse-modal__info__label">{{
                  textK('UI_CART_WAREHOUSE_MIN_QTY')
                }}</span>
                <span class="select-warehouse-modal__info__value">{{ minQty }}</span>
              </div>
              <div class="select-warehouse-modal__specs__spec" v-if="stepQty > 1">
                <span class="select-warehouse-modal__info__label">{{
                  textK('UI_CART_WAREHOUSE_PACKAGE_QTY')
                }}</span>
                <span class="select-warehouse-modal__info__value">{{ stepQty }}</span>
              </div>
            </div>
            <div class="typeset" v-if="warehouses.Warehouses && warehouses.Warehouses.length > 1">
              <p>
                {{ textK('UI_CART_WAREHOUSE_HELP_TEXT_1') }} {{ warehouses.Warehouses.length }}
                {{
                  !isReadOnly
                    ? textK('UI_CART_WAREHOUSE_HELP_TEXT_2')
                    : textK('UI_CART_WAREHOUSE_LOCATIONS_LABEL')
                }}
              </p>
            </div>

            <!-- LocationInfo for blue products -->
            <VSimpleTable
              v-if="hasLocationInfos"
              v-ui-test="'warehouse-locations'"
              class="select-warehouse-modal__table"
              :headers="headers"
            >
              <VSimpleTableRow
                v-ui-test="'warehouse-location-line'"
                class="select-warehouse-modal__row"
                v-for="(locationInfo, index) in locationInfos"
                :key="locationInfo.warehouseLocationInitials + index"
              >
                <VSimpleTableCell
                  label="warehouse-stock-status"
                  class="select-warehouse-modal__cell select-warehouse-modal__cell--name"
                >
                  <span class="select-warehouse-modal__cell-label">{{
                    textK('UI_PRODUCT_LOCATION')
                  }}</span>
                  {{
                    locationInfo.warehouseLocationDescription ||
                    locationInfo.warehouseLocationInitials
                  }}
                </VSimpleTableCell>
                <VSimpleTableCell
                  label="warehouse-product-location"
                  class="select-warehouse-modal__cell"
                >
                  <span
                    class="select-warehouse-modal__cell-label select-warehouse-modal__info__label"
                    >{{ textK('UI_PRODUCT_STOCK_STATUS') }}</span
                  >
                  <div class="select-warehouse-modal__cell__stock-wrapper">
                    <ProductAvailibilityIndication
                      class="select-warehouse-modal__stock"
                      :in-stock="locationInfo.availableStock > 0"
                      :with-lead-time="hasLeadTime"
                    >
                    </ProductAvailibilityIndication>

                    <span
                      v-if="!locationInfo.availableStock && unknownDeliveryDate"
                      class="select-warehouse-modal__info__stock"
                    >
                      {{ textK('UI_UNKNOWN_DELIVERY_DATE_AVAILABILITY') }}
                      <Tooltip>
                        <span>
                          <svgicon name="ui-info" />
                        </span>
                        <template #tooltip-content>
                          {{ textK('UI_UNKNOWN_DELIVERY_DATE_TOOL_TIP') }}
                        </template>
                      </Tooltip>
                    </span>
                    <span
                      v-else-if="!locationInfo.availableStock && availabilityDate"
                      class="select-warehouse-modal__info__stock"
                    >
                      {{ date(availabilityDate) }}
                    </span>
                    <span v-else class="select-warehouse-modal__info__stock">
                      {{ locationInfo.availableStock || 0 }}
                      <template v-if="locationInfo.availableStock || !additionalProductData">{{
                        textK('UI_PRODUCT_STOCK_LABEL')
                      }}</template>
                    </span>
                  </div>
                </VSimpleTableCell>
                <!-- TODO: We do not have cart functionality for blue products yet-->
                <!-- <VSimpleTableCell v-if="!isReadOnly" label="warehouse-product-quantity" class="select-warehouse-modal__cell select-warehouse-modal__cell--quantity">
                <QuantitySelector
                  v-ui-test="'select-warehouse-modal-quantity'"
                  class="select-warehouse-modal__quantity-selector"
                  v-model="warehouse.Quantity"
                  :minValue="minQty"
                  :step="stepQty"
                  :unit="SalesUnit"
                ></QuantitySelector>
              </VSimpleTableCell> -->
              </VSimpleTableRow>
            </VSimpleTable>

            <!-- Warehouses for red & mirakl products -->
            <VSimpleTable
              v-else-if="warehouses.Warehouses.length > 0"
              v-ui-test="'warehouse-locations'"
              class="select-warehouse-modal__table"
              :headers="headers"
            >
              <VSimpleTableRow
                v-ui-test="'warehouse-location-line'"
                class="select-warehouse-modal__row"
                v-for="warehouse in warehouses.Warehouses"
                :key="warehouse.WarehouseCode"
              >
                <VSimpleTableCell
                  label="warehouse-stock-status"
                  class="select-warehouse-modal__cell select-warehouse-modal__cell--name"
                >
                  <span class="select-warehouse-modal__cell-label">{{
                    textK('UI_PRODUCT_LOCATION')
                  }}</span>
                  {{ warehouse.WarehouseName }}
                </VSimpleTableCell>
                <VSimpleTableCell
                  label="warehouse-product-location"
                  class="select-warehouse-modal__cell"
                >
                  <span
                    class="select-warehouse-modal__cell-label select-warehouse-modal__info__label"
                    >{{ textK('UI_PRODUCT_STOCK_STATUS') }}</span
                  >
                  <div class="select-warehouse-modal__cell__stock-wrapper">
                    <ProductAvailibilityIndication
                      class="select-warehouse-modal__stock"
                      :in-stock="warehouse.WarehouseInStock"
                      :with-lead-time="hasLeadTime"
                    >
                    </ProductAvailibilityIndication>
                    <span
                      v-if="
                        !warehouse.WarehouseInStock &&
                        additionalProductData &&
                        additionalProductData.UnknownDeliveryDate
                      "
                      class="select-warehouse-modal__info__stock"
                    >
                      {{ textK('UI_UNKNOWN_DELIVERY_DATE_AVAILABILITY') }}
                      <Tooltip>
                        <span>
                          <svgicon name="ui-info" />
                        </span>
                        <template #tooltip-content>
                          {{ textK('UI_UNKNOWN_DELIVERY_DATE_TOOL_TIP') }}
                        </template>
                      </Tooltip>
                    </span>
                    <span v-else class="select-warehouse-modal__info__stock">
                      <template v-if="warehouse.WarehouseQuantity && warehouse.WarehouseQuantity">
                        {{ warehouse.WarehouseQuantity }}
                      </template>
                      <template v-else>
                        {{ textK('UI_PRODUCT_STOCK_LABEL_UNKNOWN') }}
                      </template>
                    </span>
                  </div>
                </VSimpleTableCell>
                <VSimpleTableCell
                  v-if="!isReadOnly"
                  label="warehouse-product-quantity"
                  class="select-warehouse-modal__cell select-warehouse-modal__cell--quantity"
                >
                  <QuantitySelector
                    v-ui-test="'select-warehouse-modal-quantity'"
                    class="select-warehouse-modal__quantity-selector"
                    v-model="warehouse.Quantity"
                    :min-value="minQty"
                    :step="stepQty"
                    :unit="SalesUnit"
                  ></QuantitySelector>
                </VSimpleTableCell>
              </VSimpleTableRow>
            </VSimpleTable>

            <!-- NO WAREHOUSE is available - use additional data fallback :( -->
            <VSimpleTable
              v-else
              v-ui-test="'warehouse-locations'"
              class="select-warehouse-modal__table"
              :headers="headers"
            >
              <VSimpleTableRow class="select-warehouse-modal__row">
                <VSimpleTableCell
                  label="warehouse-stock-status"
                  class="select-warehouse-modal__cell select-warehouse-modal__cell--name"
                >
                  <span class="select-warehouse-modal__cell-label">{{
                    textK('UI_PRODUCT_LOCATION')
                  }}</span>
                  {{
                    additionalProductData &&
                    additionalProductData.Warehouse &&
                    additionalProductData.Warehouse.Name
                  }}
                </VSimpleTableCell>
                <VSimpleTableCell
                  label="warehouse-product-location"
                  class="select-warehouse-modal__cell"
                >
                  <span
                    class="select-warehouse-modal__cell-label select-warehouse-modal__info__label"
                    >{{ textK('UI_PRODUCT_STOCK_STATUS') }}</span
                  >
                  <div class="select-warehouse-modal__cell__stock-wrapper">
                    <ProductAvailibilityIndication
                      class="select-warehouse-modal__stock"
                      :in-stock="additionalProductData && additionalProductData.IsInStock"
                      :with-lead-time="hasLeadTime"
                    >
                    </ProductAvailibilityIndication>
                    <span
                      v-if="additionalProductData && additionalProductData.UnknownDeliveryDate"
                      class="select-warehouse-modal__info__stock"
                    >
                      {{ textK('UI_UNKNOWN_DELIVERY_DATE_AVAILABILITY') }}
                      <Tooltip>
                        <span>
                          <svgicon name="ui-info" />
                        </span>
                        <template #tooltip-content>
                          {{ textK('UI_UNKNOWN_DELIVERY_DATE_TOOL_TIP') }}
                        </template>
                      </Tooltip>
                    </span>
                    <span v-else class="select-warehouse-modal__info__stock">{{
                      additionalProductData && additionalProductData.StockIndication
                    }}</span>
                  </div>
                </VSimpleTableCell>
                <!-- // TODO: Should be updated when we have the option to use the quantity selector for blue products -->
                <VSimpleTableCell
                  v-if="!isReadOnly && !isBlueOwningSystem"
                  label="warehouse-product-quantity"
                  class="select-warehouse-modal__cell select-warehouse-modal__cell--quantity"
                >
                  <!-- NOTICE: v-model binds to "singleDefaultWarehouse" which is pieced together from additionalData endpoint -->
                  <QuantitySelector
                    v-ui-test="'select-warehouse-modal-quantity'"
                    class="select-warehouse-modal__quantity-selector"
                    v-model="singleDefaultWarehouse.Quantity"
                    :min-value="minQty"
                    :step="stepQty"
                    :unit="PriceUnit"
                  ></QuantitySelector>
                </VSimpleTableCell>
              </VSimpleTableRow>
            </VSimpleTable>
          </div>
        </TransitionExpand>
      </template>
      <template #actions>
        <VButton
          @click="!isReadOnly ? updateWarehouses() : closeModal()"
          :loading="isBusy"
          v-ui-test="'warehouse-update'"
        >
          {{ !isReadOnly ? textK('UI_CART_WAREHOUSE_UPDATE_BTN') : textK('UI_COMMON_CLOSE') }}
        </VButton>
      </template>
    </StandardDialogModal>
  </div>
</template>

<style scoped lang="scss" src="./modal-select-warehouse.scss"></style>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import { CartApi } from '@/src/core/api';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { Req } from '@/src/core/services/requester';
import { TextKey } from '@/src/core/services/text-key';
import { useModalStore } from '@/src/core/stores/modal';
import { CartEntryDetails, OwningSystem, Product } from '@/src/core/types/api';
import {
  AirbusFHSDetails,
  IWarehouses,
  LocationInfo,
  Procurement,
} from '@/src/core/types/interfaces';
import { DateFormat } from '@/src/core/utils/dates';
import ProductAvailibilityIndication from '@/src/market/components/product/product-availibility-indication/product-availibility-indication.vue';
import QuantitySelector from '@/src/market/components/ui/quantity-selector/quantity-selector.vue';
import { formatPriceUnit } from '@/src/market/services/airbus-parts';
import { isAirbus, isSatair, productAdditionalData } from '@/src/market/services/product-parts';
import { isBlueOwningSystem } from '@/src/market/services/product-rules';
import { useCartStore } from '@/src/market/stores/cart';
import { useProductStore } from '@/src/market/stores/product';

@Component({
  components: {
    ProductAvailibilityIndication,
    QuantitySelector,
    StandardDialogModal,
    TransitionExpand,
    VButton,
    VSimpleTable,
    VSimpleTableCell,
    VSimpleTableRow,
    Tooltip,
  },
})
export class ModalSelectWarehouse extends Vue {
  public textK = useText();
  public productStore = useProductStore();
  public modalStore = useModalStore();
  public cartStore = useCartStore();
  public date = DateFormat;
  private uiVariant = UiVariant;
  public uiSize = UiSize;
  public isBusy: boolean = true;
  public warehouses: IWarehouses = {
    Warehouses: [],
  };
  public locationInfos: LocationInfo[] | undefined = [];

  public get singleDefaultWarehouse() {
    if (isSatair(this.additionalProductData)) {
      return {
        WarehouseCode: this.additionalProductData?.Warehouse?.Code,
      } as CartEntryDetails;
    }

    return {};
  }

  private get params(): IModalSelectWarehouseParams {
    return this.modalStore.params as IModalSelectWarehouseParams;
  }

  public get product(): Product | undefined {
    return this.params.offerId
      ? this.productStore.productByCode(this.params.offerId)
      : this.params.productEntry
        ? this.params.productEntry
        : undefined;
  }

  private get offerId() {
    return this.product?.Id || '';
  }

  private get productSku() {
    return this.product?.Sku || '';
  }

  private get isCalculated() {
    return Boolean(this.params?.calculated);
  }

  public get isReadOnly() {
    return Boolean(this.params?.readonly);
  }

  public get additionalProductData() {
    return productAdditionalData(this.offerId, this.owningSystem);
  }

  public get PriceUnit() {
    if (isSatair(this.additionalProductData)) {
      return this.additionalProductData
        ? this.additionalProductData.PriceUnit.Code
        : (this.product && this.product.Unit) || '';
    }

    if (isAirbus(this.additionalProductData)) {
      const priceUnit = formatPriceUnit(this.params.procurement?.priceUnit);
      return priceUnit.SapCode;
    }

    return '';
  }

  public get SalesUnit() {
    if (isSatair(this.additionalProductData)) {
      return this.additionalProductData
        ? this.additionalProductData.SalesUnit.SapCode
        : (this.product && this.product.Unit) || '';
    }

    if (isAirbus(this.additionalProductData)) {
      return this.params.procurement?.salesUnit.sapCode ?? '';
    }

    return '';
  }

  public get headers(): Array<string | undefined> {
    const headers = [TextKey('UI_PRODUCT_LOCATION'), TextKey('UI_PRODUCT_STOCK_STATUS')];

    if (!this.isReadOnly) {
      headers.push(TextKey('UI_PRODUCT_QUANTITY'));
    }

    return headers;
  }

  public get minQty() {
    return (
      (this.product && this.product.AddToBasketQty > 0 && this.product.AddToBasketQty) || undefined
    );
  }

  public get stepQty() {
    return (this.product && this.product.CartStep > 0 && this.product.CartStep) || undefined;
  }

  private get owningSystem() {
    return this.params.owningSystem;
  }

  public get isBlueOwningSystem() {
    if (this.owningSystem) {
      return isBlueOwningSystem(this.owningSystem);
    }
    return false;
  }

  public get hasLocationInfos() {
    return this.isBlueOwningSystem && this.locationInfos && this.locationInfos.length > 0;
  }

  private async fetchWarehouses() {
    const { IsSuccess, Data } = await Req(
      {
        url: CartApi.FetchWarehouses(this.offerId),
      },
      undefined,
      CartApi.handleFetchWarehouses.bind(CartApi, this.offerId),
    );

    if (IsSuccess) {
      this.warehouses = Object.assign({}, Data);
    }

    this.isBusy = false;
  }

  private get procurement() {
    return this.params.procurement;
  }

  private setLocationInfos() {
    if (this.params.isFhs && this.params.fhsDetailsProcurement) {
      this.locationInfos = this.params.fhsDetailsProcurement.locations.map((location, index) => {
        return {
          availableStock: 1,
          sortIndex: index,
          warehouseLocationInitials: location,
          warehouseLocationDescription: location,
        };
      });
    } else {
      this.locationInfos = this.procurement?.locationInfos;
    }

    this.isBusy = false;
  }

  public async updateWarehouses() {
    if (this.isBlueOwningSystem) {
      // TODO handle warehouse update once we have cart functionality for blue products
      this.closeModal();
      return;
    }

    this.isBusy = true;

    const calculated = this.isCalculated || this.$route.path.includes('cart');
    const entryDetails: CartEntryDetails[] =
      this.warehouses.Warehouses.length > 0
        ? this.warehouses.Warehouses
        : [this.singleDefaultWarehouse as CartEntryDetails];

    const { IsSuccess, Data } = await Req(
      {
        method: 'POST',
        url: CartApi.UpdateWarehouses(this.offerId, calculated),
        data: {
          entryDetails,
        },
      },
      undefined,
      CartApi.handleUpdateWarehouses.bind(CartApi, {
        productCode: this.offerId,
        calculated,
        entryDetailsData: entryDetails,
      }),
    );

    if (IsSuccess) {
      this.cartStore.setCart({ cart: Data });
      this.closeModal();
    }

    this.isBusy = false;
  }

  public closeModal() {
    this.modalStore.closeModal();
  }

  public mounted() {
    // Fetch product by code, if product code is part of modal params
    // otherwise assume that product is present through productEntry in params.
    if (this.params.offerId) {
      this.productStore.fetchAndUpdateProductByOfferId({ id: this.params.offerId }).then(() => {
        this.isBlueOwningSystem ? this.setLocationInfos() : this.fetchWarehouses();
      });
    } else {
      this.isBlueOwningSystem ? this.setLocationInfos() : this.fetchWarehouses();
    }
  }

  public get unknownDeliveryDate() {
    return this.procurement?.unknownDeliveryDate ?? false;
  }

  public get availabilityDate() {
    return this.procurement?.availabilityDate ?? null;
  }

  public get hasLeadTime() {
    if (isSatair(this.additionalProductData)) {
      if (this.warehouses.Warehouses.length === 0) {
        const stockIndication = ['In Stock', 'N/A'];
        return !stockIndication.includes(this.additionalProductData.StockIndication);
      }

      return this.additionalProductData.UnknownDeliveryDate;
    }

    if (isAirbus(this.additionalProductData)) {
      return Boolean(this.unknownDeliveryDate || this.availabilityDate);
    }

    return false;
  }
}

// Modal must be initiated with code or productEntry — or it will fail.
export interface IModalSelectWarehouseParams {
  calculated?: boolean;
  offerId?: string;
  owningSystem?: OwningSystem;
  procurement?: Procurement;
  productEntry?: Product;
  readonly?: boolean;
  isFhs?: boolean;
  fhsDetailsProcurement?: AirbusFHSDetails;
}

export default toNative(ModalSelectWarehouse);
</script>
