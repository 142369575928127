import { ErrorList, HttpResponse } from '@/src/core/apim';
import { toLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { getNotificationMapping } from '@/src/core/services/notification-mapping';
import { NotificationTypes, ValidationError } from '@/src/core/types/api';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import { defaultError } from '@/src/market/settings/error';

const FILE_NOT_FOUND_ERROR = 'FileNotFoundError';

export const handleErrorNotification = (errors: ValidationError[]) => {
  return errors.map((error: ValidationError) => ({
    ...getNotificationMapping({
      title: handleErrorTitle(error),
      description: handleErrorDescription(error),
      type: NotificationTypes.Error,
    }),
  }));
};

const handleErrorDescription = (error: ValidationError) => {
  return error.subject === 'purchaseOrderNumber'
    ? error.message.replace('for this B2B Unit', 'within your company account')
    : error.message;
};

const handleErrorTitle = (error: ValidationError) => {
  return error.type === FILE_NOT_FOUND_ERROR
    ? 'Failed to download document'
    : error.reason || 'Error';
};

export const handleErrorResponse = async (resp: HttpResponse<ErrorList>) => {
  const errorData = await resp.json();
  const notification = handleErrorNotification(errorData?.errors ?? []);

  return objectKeysToPascalCaseRecursive({
    error: defaultError,
    notification,
  });
};

export const legacyErrorHandler = async (error: any) => {
  const errorMapping = await handleErrorResponse(error);

  throw {
    response: toLegacyResponse(error, errorMapping),
  };
};

export type ErrorResponse = ReturnType<typeof handleErrorResponse>;
