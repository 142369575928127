import { OrderEntry, Product } from '@/src/core/apim';
import useText from '@/src/core/hooks/useText';
import { OwningSystem } from '@/src/core/types/api';
import { BaseOwningSystem, Seller } from '@/src/core/types/interfaces';
import { ConvertToPascalCase } from '@/src/core/utils/object';
import {
  WithRequired,
  addCommerceCDNtoUrl,
  calculateMaxInt,
} from '@/src/market/services/legacy-mapping';
import { mapPrice } from '@/src/market/services/legacy-mapping-price';
import { getProductRuleMapping } from '@/src/market/services/legacy-mapping-product-rule';

/**
 * Returns a user-friendly name for a property, or 'N/A' if the property name is null or '0'.
 *
 * @param {string} [propertyName] - The property name to check.
 * @returns {string} - The user-friendly name or 'N/A'.
 *
 * @example
 * // Returns 'N/A'
 * getFriendlyName(null);
 *
 * @example
 * // Returns 'N/A'
 * getFriendlyName('0');
 *
 * @example
 * // Returns 'Sample Name'
 * getFriendlyName('Sample Name');
 */
export const getFriendlyName = (propertyName?: string) => {
  return propertyName == null || propertyName === '0' ? 'N/A' : propertyName;
};

/**
 * Checks if the given ID corresponds to an offer.
 *
 * @param {string} id - The ID to check.
 * @returns {boolean} - True if the ID is an offer, false otherwise.
 *
 * @example
 * // Returns true
 * isOffer('offer_12345');
 *
 * @example
 * // Returns true
 * isOffer('mp_67890');
 *
 * @example
 * // Returns false
 * isOffer('product_12345');
 */
export const isOffer = (id: string): boolean => {
  return id.startsWith('offer_') || id.startsWith('mp_');
};

/**
 * Maps a SAP CC product to an expanded version of the product, without the details for the PDP
 *
 * @param {Product} product - A SAP CC product.
 * @returns {object} - An expanded version of the product.
 */
export const getProductWithBaseMapping = (product: Product, entry?: OrderEntry, newId?: string) => {
  const isMarketplace = product.owningSystem === 'MIRAKL';
  const id = newId ? newId : isMarketplace ? entry?.offerInfo?.id : product.id;
  const code = product.id ?? product.code;
  const certificate = product.certificateType
    ? getFriendlyName(product.certificateType.name)
    : 'N/A';
  const hazmatDescription = product.hazmat ? getFriendlyName(product.hazmat.name) : 'N/A';
  const hazmatCode = product.hazmat ? 'UN' + getFriendlyName(product.hazmat.code) : 'N/A';
  const productManufacturer = product.manufacturerData || {};
  const weightUnit = product.weightUnit ? getFriendlyName(product.weightUnit.sapCode) : '';
  const dimensionUnit = product.dimensionsUnit
    ? getFriendlyName(product.dimensionsUnit.sapCode)
    : '';
  const volumeUnit = product.volumeUnit ? getFriendlyName(product.volumeUnit.sapCode) : '';
  const height =
    product.height && product.height !== 0
      ? getFriendlyName(product.height ? String(product.height) : undefined)
      : undefined;
  const width =
    product.width && product.width !== 0
      ? getFriendlyName(product.width ? String(product.width) : undefined)
      : undefined;
  const length =
    product.length && product.length !== 0
      ? getFriendlyName(product.length ? String(product.length) : undefined)
      : undefined;
  const grossWeight = getFriendlyName(
    product.grossWeight ? String(product.grossWeight) : undefined,
  );
  const netWeight = getFriendlyName(product.netWeight ? String(product.netWeight) : undefined);
  const limitedByCageCode = product.productRule?.sellable === 'LIMITED_BY_CAGE_CODE';
  const supplierDisplay =
    product.owningSystem?.toLocaleLowerCase?.() === 'blue' ? 'Airbus' : 'Satair';
  const multiDimensional = product.multidimensional ?? false;
  const images = product.images ?? [];
  const hasImage = Boolean(product.images?.length);
  const supplierLogo = product.newSearchResultLogo
    ? addCommerceCDNtoUrl(product.newSearchResultLogo)
    : '';

  // PRODUCT QUANTITY MAPPING

  const unitConversionDisplayList = product.unitConversions
    ?.sort((a, b) => Number(a.isSalesUnitConversion) - Number(b.isSalesUnitConversion))
    ?.map(
      (x) => `${x.unitQuantity} ${x.unit?.sapCode} = ${x.baseUnitQuantity} ${x.baseUnit?.sapCode}`,
    );

  const unit =
    product.unitConversions?.find?.((x) => x.isSalesUnitConversion)?.baseUnit?.sapCode ||
    product?.unit ||
    product.salesUnit?.code ||
    '';

  const zTelCageCode = 'C1572';
  const isSatairOrAirbusMasterShop =
    product.owningSystem?.toLowerCase() === BaseOwningSystem.Blue ||
    product.owningSystem?.toLowerCase() === BaseOwningSystem.Red;
  const isZtel = product.manufacturerData?.cageCode === zTelCageCode && isSatairOrAirbusMasterShop;

  const offerUrl = `/${productManufacturer?.cageCode}/${product?.manufacturerAid}/${
    product.owningSystem?.toLowerCase?.() === BaseOwningSystem.Mirakl
      ? id
      : product.owningSystem?.toLowerCase() === BaseOwningSystem.Blue
        ? Seller.Airbus
        : Seller.Satair
  }`.toLowerCase();
  const marketOfferUrl = `/market/product${offerUrl}`;
  const sku = `${product.manufacturerAid}:${productManufacturer?.cageCode}`;
  const hasPrice = Number(product.price?.value) > 0;
  const hasVolumePrices = Boolean(product.volumePrices?.length);
  const hasHazmat = hazmatDescription !== 'N/A';
  const hasCertificate = certificate !== 'N/A';

  const { showStock, showPrice, showOrderButton, ruleStatus } = getProductRuleMapping(
    product.productRule!,
  );

  const enginePart = product.enginePart ?? false;
  const flammable = product.flammable ?? false;

  const maxInt = Math.max(
    product.minOrderQuantity ?? 0,
    product.minimumOrderQuantity ?? 0,
    product.quantityStep ?? 0,
    product.roundingValue ?? 0,
    product.packageQuantity ?? 0,
  );

  const packageQuantity =
    product.quantityStep &&
    product.quantityStep > 1 &&
    product.quantityStep > (product.packageQuantity ?? 0)
      ? product.quantityStep
      : product.packageQuantity && product.packageQuantity > 1
        ? product.packageQuantity
        : 1;

  const adjustedMaxInt =
    maxInt % packageQuantity === 0 ? maxInt : calculateMaxInt(maxInt, packageQuantity);

  const addToBasketQty = packageQuantity < adjustedMaxInt ? adjustedMaxInt : packageQuantity;

  const cartStep =
    product.roundingValue && product.roundingValue > packageQuantity
      ? product.roundingValue
      : packageQuantity;

  const slowMoverPriceAvailable = false;
  const priceHidden = false;
  const roundingValue = 0;
  const volumePrices = product.volumePrices || [];
  const hasFHS = product.hasFHS || false;
  const offerData = [];
  const exportControlData = [];
  const allowQuoteRequests = false;
  const isAirbusHazmat = product.isAirbusHazmat || false;
  const airbusProprietary = product.airbusProprietary || false;
  const isKit = product.isKit || false;
  const isTool = product.isTool || false;

  return {
    ...product,
    id,
    code,
    certificate,
    hazmatDescription,
    hazmatCode,
    productManufacturer,
    weightUnit,
    dimensionUnit,
    volumeUnit,
    height,
    width,
    length,
    grossWeight,
    netWeight,
    limitedByCageCode,
    supplierDisplay,
    multiDimensional,
    images,
    hasImage,
    supplierLogo,

    isMarketplace,

    unitConversionDisplayList,
    unit,

    isZtel,

    offerUrl,
    marketOfferUrl,
    sku,
    hasPrice,
    hasVolumePrices,
    hasHazmat,
    hasCertificate,

    showStock,
    showPrice,
    showOrderButton,
    ruleStatus,

    enginePart,
    flammable,

    packageQuantity,
    adjustedMaxInt,
    addToBasketQty,
    cartStep,

    owningSystem: product.owningSystem as OwningSystem, // this is just for the types
    hasFHS,
    isAirbusHazmat,
    airbusProprietary,
    isKit,
    isTool,

    // properties below are static and should probably be removed
    // ask SAP CC?
    interchangeable: false,
    isUnknownProduct: false,
    price: mapPrice(),
    slowMoverPriceAvailable,
    priceHidden,
    roundingValue,
    volumePrices,
    offerData,
    exportControlData,
    allowQuoteRequests,
  };
};

type ProductWithBaseMapping = ReturnType<typeof getProductWithBaseMapping>;

/**
 * Maps a SAP CC product to an expanded version of the product, including the PDP details
 *
 * @param {Product} product - A SAP CC product.
 * @returns {ProductWithMapping} - An expanded version of the product.
 */
export const getProductWithMapping = (product: Product, entry?: OrderEntry) => {
  const productWithBaseMapping = getProductWithBaseMapping(product, entry);
  const toReturn = {
    ...productWithBaseMapping,
    ...getProductDetails(productWithBaseMapping),
  };

  return toReturn as WithRequired<typeof toReturn, 'id' | 'owningSystem' | 'code'>;
};
export type ProductWithMapping = ReturnType<typeof getProductWithMapping>;
export type LegacyProduct = ConvertToPascalCase<ProductWithMapping>;

/**
 * Retrieves master data details of the product.
 *
 * @param {ProductWithBaseMapping} product - A mapped product.
 * @returns {Array<object>} - An array of master data attributes.
 */
const getMasterData = (product: ProductWithBaseMapping) => {
  const textK = useText();
  const noDataText = textK('UI_PRODUCTPAGE_MASTERDATA_NODATA') || 'No information available';

  return [
    { Name: 'Hazmat UN code:', Value: product.hazmatCode, TestTag: 'hazmat-code' },
    {
      Name: 'Hazmat description:',
      Value: product.hazmatDescription,
      TestTag: 'hazmat-description',
    },
    { Name: 'Flammable:', Value: product.flammable ? 'Yes' : '', TestTag: 'flammable' },
    { Name: 'Engine part:', Value: product.enginePart ? 'Yes' : '', TestTag: 'engine-part' },
    { Name: 'Approved suppliers', Value: 'approved-suppliers', TestTag: 'approved-suppliers' },
    {
      Name: 'Alternative part number',
      Value: product.alternativePartNumbers?.length ? product.alternativePartNumbers : '',
      TestTag: 'alterProductNo',
    },
    {
      Name: 'Serialized part:',
      Value:
        typeof product.serialNumberRequired !== 'undefined'
          ? product.serialNumberRequired
            ? 'Yes'
            : ''
          : noDataText,
      TestTag: 'serialNumberRequired',
    },
    {
      Name: 'Total shelf life:',
      Value:
        product.maxShelfLife && product.maxShelfLife !== 0
          ? product.maxShelfLife + ' ' + product.lifeTimeIndicator + '(s)'
          : noDataText,
      TestTag: 'maximum-shelf-life',
    },
    {
      Name: 'International commodity code:',
      Value: product.commodityCode
        ? product.commodityCode
        : product.owningSystem == 'BLUE'
          ? noDataText
          : '',
      TestTag: 'international-commodity-code',
    },
    {
      Name: 'Periodic check interval:',
      Value:
        product.airbusPeriodicalCheckInterval &&
        product.owningSystem == 'BLUE' &&
        product.airbusPeriodicalCheckInterval != '0'
          ? product.airbusPeriodicalCheckInterval
          : '',
      TestTag: 'AirbusPeriodicalCheckInterval',
    },
    {
      Name: 'Periodic check type:',
      Value: product.airbusPeriodicalCheck
        ? `${product.airbusPeriodicalCheck.name} (${product.airbusPeriodicalCheck.code})`
        : '',
      TestTag: 'AirbusPeriodicalCheck',
    },
    { Name: 'Product colour:', Value: product.colour ? product.colour : '', TestTag: 'colour' },
    {
      Name: 'Aircraft validity:',
      Value: product.applicability ? product.applicability : '',
      TestTag: 'aircraftvalidity',
    },
    {
      Name: 'Consumable material list (CML):',
      Value: product.cml ? product.cml : '',
      TestTag: 'cml',
    },
    {
      Name: 'ATA Chapter(s) validity:',
      Value: product.ataChapter ? product.ataChapter : '',
      TestTag: 'ata',
    },
  ];
};

/**
 * Retrieves measurements data details of the product.
 *
 * @param {ProductWithBaseMapping} product - A mapped product.
 * @returns {Array<object>} - An array of measurements data attributes.
 */
const getMeasurements = (product: ProductWithBaseMapping) => {
  const textK = useText();
  const noDataText = textK('UI_PRODUCTPAGE_MEASUREMENTS_NODATA') || 'No measurement available';

  return [
    {
      Name: 'Net weight:',
      Value:
        product.netWeight !== 'N/A' ? product.netWeight + ' ' + product.weightUnit : noDataText,
      TestTag: 'net-weight',
    },
    {
      Name: 'Gross weight:',
      Value:
        product.grossWeight !== 'N/A' && product.grossWeight !== '0.01'
          ? product.grossWeight + ' ' + product.weightUnit
          : noDataText,
      TestTag: 'gross-weight',
    },
    {
      Name: 'Height:',
      Value:
        typeof product.height === 'string'
          ? product.height + ' ' + product.dimensionUnit
          : noDataText,
      TestTag: 'height',
    },
    {
      Name: 'Length:',
      Value:
        typeof product.length === 'string'
          ? product.length + ' ' + product.dimensionUnit
          : noDataText,
      TestTag: 'length',
    },
    {
      Name: 'Width:',
      Value:
        typeof product.width === 'string'
          ? product.width + ' ' + product.dimensionUnit
          : noDataText,
      TestTag: 'width',
    },
    {
      Name: 'Volume:',
      Value: product.volume ? product.volume + ' ' + product.volumeUnit : noDataText,
      TestTag: 'volume',
    },
  ];
};

/**
 * Retrieves export-control data details of the product.
 *
 * @param {ProductWithBaseMapping} product - A mapped product.
 * @returns {Array<object>} - An array of export-control data attributes.
 */
const getExportControlData = (product: ProductWithBaseMapping) => {
  const textK = useText();
  const noDataText = textK('UI_PRODUCTPAGE_EXPORTCONTROL_NODATA') || 'No information available';

  return [
    {
      Name: 'Function:',
      Value: product?.function ?? '',
      TestTag: 'function',
    },
    {
      Name: 'Material composition:',
      Value: product.materialComposition ?? '',
      TestTag: 'materialComposition',
    },
    {
      Name: 'Next higher assembly:',
      Value: product.nextHigherAssembly ?? '',
      TestTag: 'nextHigherAssembly',
    },
    {
      Name: 'HS/HTS code / ECCN:',
      Value: product.eccn ?? '',
      TestTag: 'eccn',
    },
  ];
};

/**
 * Retrieves detailed product information including master data, measurements, and export control data.
 *
 * @param {ProductWithBaseMapping} product - A mapped product.
 * @returns {Array<object>} - An object containing master data, measurements, and export control data.
 */
const getProductDetails = (product: ProductWithBaseMapping) => {
  return {
    masterData: getMasterData(product),
    measurements: getMeasurements(product),
    exportControlData: getExportControlData(product),
  };
};

export const legacyEmptyProduct = {};

export const setProductQty = (
  product: ProductWithBaseMapping,
  quantity: number | null,
): number | null => {
  if (quantity !== 0 && quantity! < product.addToBasketQty) {
    quantity = product.addToBasketQty;
  }

  // Round up - if quantity is below packageQuantity or cartStep
  const t = product.packageQuantity < product.cartStep ? product.cartStep : product.packageQuantity;

  if (quantity! % t !== 0) {
    quantity = Math.ceil((quantity! as number) / t) * t;
  }

  return quantity;
};
